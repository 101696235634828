import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import {responseMsg} from "../common/ResponseMsg";
import { AgGridReact } from "ag-grid-react";
import {CommonContext} from "../../App";
import { range } from "lodash";
import Swal from "sweetalert2";
import DatePicker from "react-datepicker"
import ko from "date-fns/locale/ko"
import moment from "moment";

function GridTable(props) {
    const [insertRole,setInsertRole] = useState(true);
    const [updateRole,setUpdateRole] = useState(true);
    const [searchVal,setSearchVal] = useState({});
    const userRoleCheck = useContext(CommonContext).userRoleCheck;

    const gridRef = useRef(null);

    const autoSizeAll = useCallback((skipHeader) => {
        const allColumnIds = [];
        gridRef.current.columnApi.getColumns().forEach((column) => {
          allColumnIds.push(column.getId());
        });
        gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);

    }, []);

    const commonSearch = (searchSetter) => {

        searchSetter({req:searchVal});
    }

    useEffect(() => {
        const defaultVal = {};

        props?.header?.search?.option.forEach(ele => {
            defaultVal[ele.field] = ele.default; 
        })

        if (props?.header?.search?.dateOption) {
            const dateOption = props.header.search.dateOption;

            defaultVal[dateOption.start.field] = dateOption.start.default;
            defaultVal[dateOption.end.field] = dateOption.end.default;
        }

        setSearchVal(defaultVal);
        props?.header?.search?.searchSetter({req:defaultVal});

        const promiseIn = userRoleCheck({role:`${props.menu}_INSERT`});
        promiseIn.then(result => setInsertRole(result))

        const promiseUp = userRoleCheck({role:`${props.menu}_UPDATE`});
        promiseUp.then(result => setUpdateRole(result))

    },[])

    return (
        <div id="gridTable">
            {props.header &&
                (       
                    <div className={props.block ? "grid_header block" : "grid_header"} style={{flexDirection:"column"}}>
                        <div style={{width:'100%',display:'flex'}}>
                            {props.header.search &&
                                <div className="search_wrap"> 
                                    {props.header.search.option?.map(ele => {

                                        return (
                                            <div className="option_wrap" key={ele.field} data-key={ele.field}>
                                                <div className="option_title">{ele.name}</div>

                                                {ele.select ? 
                                                    <select
                                                        key={ele.field}
                                                        onChange={({target}) => {
                                                            setSearchVal({...searchVal,[ele.field]:target.value})
                                                        }}
                                                    >
                                                        {ele.select.map(ele2 => {

                                                            return (
                                                                <option key={ele2.name} value={ele2.value}>
                                                                    {ele2.name}
                                                                </option>
                                                            )
                                                        })}
                                                    </select> 
                                                    :
                                                    <input 
                                                        type="text"
                                                        value={searchVal[ele.field] || ''}
                                                        onChange={({target}) => {
                                                            setSearchVal({...searchVal,[ele.field]:target.value})
                                                        }}
                                                        onKeyPress={(e) => {
                                                            if (e.key === "Enter") {
                                                                commonSearch(
                                                                    props.header.search.searchSetter,
                                                                );
                                                            }
                                                        }}
                                                    />
                                                }
            
                                            </div>
                                        )
                                    })}

                                    {props.header.search.dateOption?.type === 'range' && 
                                    (() => {
                                        const dateOption = props.header.search.dateOption;

                                        return (
                                            <div className="option_wrap" key="installDt" data-key="installDt">
                                                <div className="option_title">{dateOption.name}</div>

                                                <DatePicker 
                                                    selected={searchVal[dateOption.start.field] ? new Date(searchVal[dateOption.start.field]) : ''} 
                                                    onChange={date => setSearchVal({...searchVal, ...(date ? {[dateOption.start.field]:moment(date).format('YYYY.MM.DD HH:mm:ss')} : {[dateOption.start.field]:''})})}
                                                    dateFormat='yyyy-MM-dd' 
                                                    locale={ko}
                                                /> 

                                                <span style={{padding:"6px"}}>~</span>

                                                <DatePicker 
                                                    selected={searchVal[dateOption.end.field] ? new Date(searchVal[dateOption.end.field]) : ''} 
                                                    onChange={(date) => setSearchVal({...searchVal, ...(date ? {[dateOption.end.field]:moment(date).format('YYYY.MM.DD HH:mm:ss')} : {[dateOption.end.field]:''})})}
                                                    dateFormat='yyyy-MM-dd'
                                                    locale={ko}
                                                /> 
                                            </div>     
                                        )
                                    })()}
                                </div>
                            }

                            { props.customSearch && ( props.customSearch ) }
                                
                            { !props.customSearch && 
                            <div className="button_wrap">
                                {props.header.add && insertRole && <button 
                                                                className="btn_add" 
                                                                onClick={() => {
                                                                        props.header.add(props.header.type)
                                                                }}

                                                            >
                                                            등록</button>
                                }

                                {props.header.search && <button    
                                                            className="btn_search"
                                                            onClick={() => {
                                                                commonSearch(
                                                                        props.header.search.searchSetter,
                                                                    );
                                                            }}
                                                            
                                                            >
                                                            검색</button>
                                }
                            </div>
                            }
                        </div>

                        {props.colChange && 
                            <div className="option_wrap col_change_wrap">
                                <div className="option_title">항목</div>
                                {props.config.columnDefs.map((item, idx) => {
                                    return (
                                        <button 
                                            key={item.field}
                                            data-key={item.field}
                                            className={item.hide ? `btn_item hide` : `btn_item`} 
                                            onClick={({target}) => {
                                                const newCol = props.config.columnDefs.map(ele => {

                                                    if (ele.field === target.dataset.key) {
                                                        ele.hide = !ele.hide;
                                                    }
    
                                                    return ele;
                                                })

                                                props.chageColumnDefs(newCol);
                                                gridRef.current.api.setColumnDefs(newCol,gridRef.current.api);
                                            }}
                                        >
                                            {item.headerName}
                                        </button>
                                    )
                                })}
                            </div>
                        }
                    </div>
                )
            }

            <div className={props.block ? "grid_wrap block" : "grid_wrap" } style={{height:"100%"}}>
                <div
                    style={{...props.style}} 
                    className={props.type === 'modal' ? 'ag-theme-alpine grid modal_grid' : 'ag-theme-alpine grid page_grid'}>
                    <AgGridReact
                        ref={gridRef}
                        {...props.config}
                        {...(updateRole || {onCellDoubleClicked: () => Swal.fire({text:'수정권한이 없습니다.', icon: 'error',}) })}
                        suppressPaginationPanel={true}
                        onModelUpdated={(evt) => evt.api.forEachNodeAfterFilterAndSort((ele,idx) => {

                            if (props.selectedRow && props.selectedRow.row) {

                                if (ele.data[props.selectedRow.check] === props.selectedRow.row[props.selectedRow.check]) {
                                    ele.setSelected(true);
                                }

                            } else if (idx === 0 && !props.config.suppressRowHoverHighlight && !props.notSelected){
                                ele.setSelected(true);
                            }
                        })}
                        onSortChanged={param => {
                            const sorted = param.columnApi.getColumnState().filter(s => s.sort !== null);

                            if (sorted.length) {
                                props.header.search.sortSetter(sorted.map(ele => ({header:ele.colId,sort:ele.sort})));
                            } else {
                                props.header.search.sortSetter(null);
                            }
                        }}
                        overlayNoRowsTemplate={"데이터가 없습니다."}
                    />
                </div>

            </div>

            {props.pagination &&
                <div className="pagination block">
                    <ul className="paging">
                        <li onClick={() => props.pageClick(props.pagination.firstPageNo,props.pageFormData)}>처음</li>

                        <li 
                            className={props.pagination.currentPageNo === props.pagination.prevPageNo ? 'disabled' : ''}
                            onClick={() => props.pageClick(props.pagination.prevPageNo,props.pageFormData)}
                        >{"<"}</li>

                        {range(props.pagination.startPageNo,props.pagination.endPageNo + 1).map(ele => {

                            return (
                                <li 
                                    key={ele}
                                    className={props.pagination.currentPageNo === ele ? 'select' : ''}
                                    onClick={() => props.pageClick(ele,props.pageFormData)}
                                >{ele}</li>
                            )
                        })}

                        <li 
                            className={props.pagination.currentPageNo === props.pagination.nextPageNo ? 'disabled' : ''}
                            onClick={() => props.pageClick(props.pagination.nextPageNo,props.pageFormData)}
                        >{">"}</li>

                        <li onClick={() => props.pageClick(props.pagination.finalPageNo,props.pageFormData)}>끝</li>
                    </ul>
                </div>
            }

        </div>
    );
}

export default GridTable;