import UserStyle from './UserStyle.module.css';
import React, { useEffect, useState, useMemo, useContext } from "react";
import { Routes, Route, Navigate, useNavigate, json } from "react-router-dom";
import { CommonContext } from "../../../App.js";
import { Checkbox } from "primereact/checkbox";
import { responseMsg } from "../../common/ResponseMsg.js";
import { useForm, Controller } from "react-hook-form";
import { Button } from "primereact/button";
import Swal from "sweetalert2";

function SmsModal({ closeSmsModal, userId }) {
    const { register, handleSubmit, reset, formState: { isSubmitting, isDirty, errors } } = useForm();
    const [ smsCodes, setSmsCodes] = useState({all:[],my:[]});
    const [ checkBox, setCheckBox] = useState({userId:userId,codes:[]});
    const [ allCheck, setAllCheck] = useState(false);

    const checkBoxChangeHandler = (event) => {
        const isChecked = event.target.checked ? true : false;

        if (isChecked) {
            setCheckBox({...checkBox, codes:[...checkBox.codes,{id:event.target.name}]});
        } else {
            setCheckBox({...checkBox, codes:checkBox.codes.filter(ele => ele.id !== event.target.name)});
        }
    }

    useEffect(() => {
        fetch(`/api/system/codes/SENSOR/42/${userId}`,{
            method:"GET"
    })
        .then((res) => res.json())
        .then((json) => {
            console.log(json)
            if (json.code === responseMsg.SUCCESS.code) {
                setSmsCodes(json.data);
                setCheckBox({...checkBox,codes:json.data.my.map(ele => ({id:ele.id}))});
            } else if (json.code === responseMsg.FAIL.code) {
                alert('통신이 지연되고 있습니다..');
            }
        })
        .catch((error) => {
            console.log(error)
        });

    }, [])

    const onSubmitHandler = (formData) => {
        fetch(`/api/system/users/sms`,{
            method:"PATCH",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(checkBox),
    })
        .then((res) => res.json())
        .then((json) => {
            if (json.code === responseMsg.SUCCESS.code) {
                Swal.fire('수정이 완료됐습니다.')
                closeSmsModal();

            } else if (json.code === responseMsg.FAIL.code) {
                alert('통신이 지연되고 있습니다..');
            }
        })
        .catch((error) => {
            console.log(error)
        });
    }

    return (
        <div>
            <form className="input_wrap">
                <div className={UserStyle.sms}>
                    <label>전체</label>

                    <Checkbox 
                        name='all' 
                        checked={allCheck} 
                        onChange={({target}) => {
                            setAllCheck(target.checked)

                            if (target.checked) {
                                setCheckBox({...checkBox, codes:smsCodes.all.map(ele => ({id:ele.id}))});
                            } else {
                                setCheckBox({...checkBox, codes: []});
                            }
                        }}
                    />
                </div>

                {smsCodes.all.map((ele) => {

                    return (
                        <div className={UserStyle.sms} key={ele.id}>
                            <label>{ele.name}</label>

                            <Checkbox 
                                name={ele.id} 
                                checked={checkBox.codes.find(ele2 => ele2.id === ele.id) ? true : false} 
                                onChange={checkBoxChangeHandler}
                            />
                        </div>
                    )
                    
                })}
            </form>

            <footer>
                <Button
                    label="취소"
                    severity="secondary"
                    raised
                    icon="pi pi-times"
                    size="sm"
                    onClick={closeSmsModal}
                />
                <Button
                    label="수정"
                    severity="success"
                    raised
                    icon="pi pi-check"
                    size="sm"
                    onClick={handleSubmit(onSubmitHandler)}
                />
            </footer>
        </div>
    );
}

export default SmsModal;