import loginStyle from "./LoginStyle.module.css"
import React, { useEffect, useState, useContext } from "react";
import {Routes, Route, Navigate, useNavigate} from "react-router-dom";
import {responseMsg} from "../common/ResponseMsg";
import { useForm} from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

function FindPassword({openState}) {
    const { register, handleSubmit, formState: { isSubmitting, isDirty, errors }} = useForm();
    const navigate = useNavigate();

    const onSubmit = (formData) => {
        const formDataObj = new FormData();
        Object.keys(formData).forEach(ele => formDataObj.set(ele,formData[ele]))

        fetch("/api/system/users/tmp-password", {
            method : 'post',
            body: formDataObj
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    Swal.fire("이메일로 임시 비밀번호가 전송됐습니다.")

                } else if(json.code === responseMsg.TMP_PASSWORD_FAIL.code) {
                    alert(responseMsg.TMP_PASSWORD_FAIL.message)

                } else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }

    return (
        <form 
            className={openState.isPwOpen ? loginStyle.form_open : loginStyle.form}
            style={{
                zIndex: `${openState.isPwOpen ? 3 : 2}`
            }}
        >
            <div className={loginStyle.header}>
                <span className={loginStyle.logo}></span>
                <span className={loginStyle.title}>비밀번호 재발급</span>
            </div>
            <div className={loginStyle.body}>
                <div className={loginStyle.inputGroup}>
                    <label className={loginStyle.label}>아이디</label>
                    <input
                        className={loginStyle.input}
                        type="text"
                        name="userId"
                        {...register("userId")}
                    />
                </div>
                <div className={loginStyle.inputGroup}>
                    <label className={loginStyle.label}>이메일</label>
                    <input
                        className={loginStyle.input}
                        type="email"
                        name="email"
                        {...register("email")}
                    />
                </div>
            </div>

            <div className={loginStyle.submitWrap}>
                <button className={loginStyle.submit} type="submit" onClick={handleSubmit(onSubmit)}>임시 비밀번호 발급</button>
            </div>

            <div className={loginStyle.switchWrap}>
                <div><FontAwesomeIcon icon={faReply} rotation={180} color="#83878f"/></div>
                <div className={loginStyle.switch} onClick={() => {
                    openState.setPwOpen(false)
                    openState.setLoginOpen(true)
                }}>
                    로그인
                </div>
            </div>
        </form>
    );
}

export default FindPassword;