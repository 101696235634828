import React, { useEffect, useState, useContext } from "react";
import {Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { Card as PrimeCard } from 'primereact/card';
import {CommonContext} from "../../../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTemperatureLow, faDroplet, faWind, faCircleArrowRight} from "@fortawesome/free-solid-svg-icons";

function MapCard({onClick,data,move,popup,detailOn,drag,back}){
    const [sensorPageRole,setSensorPageRole] = useState();
    const userRoleCheck = useContext(CommonContext).userRoleCheck;
    const navigate = useNavigate();

    useEffect(() => {
        if (detailOn) {
            const promise = userRoleCheck({page:'/sensor'});
            promise.then(result => setSensorPageRole(result))
        }
    },[])

    if (detailOn) {

        return (
            <div className="map_detail">
                <ul className="body">
                    <li className="shadow">
                        <div>관리번호</div>
                        <div>{data.manageCd}</div>
                    </li>
                    <li className="shadow">
                        <div>IMEI</div>
                        <div>{data.imei}</div>
                    </li>
                    <li className="shadow">
                        <div>주소</div>
                        <div>{data.address}</div>
                    </li>
                    <li className="shadow">
                        <div>온도</div>
                        <div>{data.lastTemp}°C</div>
                    </li>
                    <li className="shadow">
                        <div>습도</div>
                        <div>{data.lastHumidity}%</div>
                    </li>
                    <li className="shadow">
                        <div>풍속</div>
                        <div>{data.lastWind}m/s</div>
                    </li>
                    <li className="shadow">
                        <div>표출문구</div>
                        <div className={data.statusGroup}>{data.status}</div>
                    </li>
                    <li className="shadow">
                        <div>설치일자</div>
                        <div>{data.installDt}</div>
                    </li>
                </ul>

                <div className="button_wrap">
                    {sensorPageRole && <div className="move_sensor" onClick={() => navigate(`/sensor?IMEI=${data.imei}`)}>제품현황</div>}
                    <div className="back" onClick={back}>검색목록</div>
                </div>

            </div>
        )
    } 
    
    else {

        return (
            <div className={`${drag ? `map_card ${drag.size} shadow` : "map_card shadow"}`} onClick={(evt) => {onClick(evt,data)}}>
                <div className="header">
                    <div className="inner">
                        <div className="manage_cd">
                            {data.manageCd}
                        </div>
                        <div className="address">
                            {data.address}
                        </div>
                    </div>
    
                    <div className={`status ${data.statusGroup}`}>
                        {data.status}
                    </div>
    
                    <div className="move_btn-wrap">
                        <button onClick={() => {
                            move(data,popup);
                        }}><FontAwesomeIcon icon={faCircleArrowRight}/></button>
                    </div>
                </div>

                <ul className="data">
                    <li>
                        <div><FontAwesomeIcon icon={faTemperatureLow} color="red"/></div>
                        <div>{data.lastTemp}°C</div>
                    </li>
                    <li>
                        <div><FontAwesomeIcon icon={faDroplet} color="blue"/></div>
                        <div>{data.lastHumidity}%</div>
                    </li>
                    <li>
                        <div><FontAwesomeIcon icon={faWind} color="green"/></div>
                        <div>{data.lastWind}m/s</div>
                    </li>
                </ul>
            </div>
        );
    }
}

export default MapCard;