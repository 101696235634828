import { useEffect, useState } from 'react';

function useInterval(callback, delay, dep) {

  useEffect(() => {
    const id = setInterval(() => {
      callback();

    }, delay);

    // if(status === '1'){
    //   clearInterval(id);
    // }

    return () => clearInterval(id);

  }, [...dep]);
}

export default useInterval;