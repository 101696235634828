import MainStyle from "./MainStyle.module.css";
import React, { useEffect, useState, useContext, useRef } from "react";
import {responseMsg} from "../common/ResponseMsg";
import Map from "../common/map/Map"
import useInterval from "../common/useInterval";

function Main({name}) {
    const [render, setRender] = useState(false);
    const [drag, setDrag] = useState({mobile:document.documentElement.clientWidth <= 580});
    const [sensorData, setSensorData] = useState([]);
    const [searchData, setSearchData] = useState();
    const [reqCnt, setReqCnt] = useState(1);

    const rendering = () => {
        setRender(!render);
    }

    const mouseMoveHandler = function (e) {
        if (drag.searchRef) {
            const resizer = e.target;
            const searchMain = drag.searchRef.current.querySelector('.search_main')
    
            // 마우스가 움직이면 기존 초기 마우스 위치에서 현재 위치값과의 차이를 계산
            const dx = e.clientX - drag.x;
            const dy = e.clientY - drag.y;
        
            // 크기 조절 중 마우스 커서를 변경함
            // class="resizer"에 적용하면 위치가 변경되면서 커서가 해제되기 때문에 body에 적용
            document.body.style.cursor = 'ew-resize';
            
            // 이동 중 양쪽 영역(왼쪽, 오른쪽)에서 마우스 이벤트와 텍스트 선택을 방지하기 위해 추가
            drag.searchRef.current.style.userSelect = 'none';
            
            // 초기 width 값과 마우스 드래그 거리를 더한 뒤 상위요소(container)의 너비를 이용해 퍼센티지를 구함
            // 계산된 퍼센티지는 새롭게 left의 width로 적용
            let newLeftWidth = (drag.width + dx);

            const dragState = {x:e.clientX,y:e.clientY,width:newLeftWidth,searchRef:drag.searchRef};


            if (newLeftWidth > 370) {
                dragState.size = 'md';
            } else {
                dragState.size = 'sm';
            }

            setDrag(dragState);
        }
    };
    
    const mouseUpHandler = function (e) {
        if (drag.searchRef) {
            
            // 모든 커서 관련 사항은 마우스 이동이 끝나면 제거됨
            document.body.style.removeProperty('cursor');
            drag.searchRef.current.style.removeProperty('user-select');
        
            setDrag({...drag,searchRef:null});
        }
    };

    const reqSensorData = () => {
        if (!searchData) {
            fetch(`/api/system/sensors`,{
                method:"GET"
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    setSensorData({all:json.data});

                } else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch((error) => {
                console.log(error)
            });

        } else {

            fetch(`/api/system/sensors/main-search?text=${searchData.text}&category=${searchData.category}`,{
                method:"GET"
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    setSensorData(json.data);

                } else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');

                } else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch((error) => {
                console.log(error)
            });
        }
    }

    useInterval(() => {
        setReqCnt(reqCnt + 1)
        reqSensorData();
    },10000,[searchData,reqCnt])

    useEffect(() => {        
        reqSensorData();
    },[searchData])

    return (
        <div id={MainStyle.mapContents} className="contents" onMouseMove={mouseMoveHandler} onMouseUp={mouseUpHandler}>
            <div className={`page_contents ${MainStyle.contents}`}>
                <div className={`${MainStyle.mapWrap}`}>
                    <Map
                        rendering={rendering}
                        reqSensorData={reqSensorData}
                        setSearchData={setSearchData}
                        sensorData={sensorData}
                        setReqCnt={setReqCnt}
                        drag={drag}
                        setDrag={setDrag}
                        reqCnt={reqCnt}
                    />
                </div>
            </div>
        </div>
    )
}

export default Main;