import "ol/ol.css";
import React, { useEffect, useState, useContext, useRef } from "react";
import MapCard from "./MapCard";
import { responseMsg } from "../ResponseMsg";
import { Splitter, SplitterPanel } from 'primereact/splitter';
import { faLeftLong,faRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import {Map as OlMap} from "ol";
import View from 'ol/View';
import {
  Circle as CircleStyle,
  Fill,
  Icon,
  Stroke,
  Style,
  RegularShape,
  Text,
} from 'ol/style';
import {Cluster, Vector as VectorSource, XYZ, OSM} from 'ol/source';
import {LineString, Point, Polygon} from 'ol/geom';
import {Tile as TileLayer, Vector as VectorLayer, Layer} from 'ol/layer';
import {createEmpty, extend, boundingExtent, getCenter, getWidth, getHeight, getSize, getArea} from 'ol/extent';
import {fromLonLat, transform, get as getProj } from 'ol/proj';
import Overlay from 'ol/Overlay';
import {Control, defaults as defaultControls, ZoomSlider, ZoomToExtent, FullScreen} from 'ol/control';
import {composeCssTransform} from 'ol/transform.js';
import { ReactComponent as MapSvg } from "../../../assets/img/map/south-korea.svg";

// 상단 센서 상태 컨트롤러
class SensorStatusControl extends Control {
    constructor(opiton) {
        
        super({
            element: opiton.ref.current,
        });
    }
}

// 검색 유틸 컨트롤러
class SearchControl extends Control {
    constructor(opiton) {

        super({
            element: opiton.ref.current,
        });
    }
}

class ZoomToExtentCus extends ZoomToExtent {
    constructor(opiton) {

        super({
            ...opiton,
        });

        this.element.querySelector('button').addEventListener('click',() => {
            opiton.removePopup();
        })
    }
}

function Map({sensorData,setSearchData,reqCnt,setReqCnt,drag,setDrag,rendering}) {
    // 지도 기본 상수
    const MAP_EXTENT = [12806977.001254011, 3851176.7469184576, 15611298.694980558, 4954315.939130121] // 이동제한 범위

    const [mapObject, setMapObject] = useState({}); // 맵 객체
    const [mapLayer, setMapLayer] = useState({}); // 맵의 마커 + 클러스터 레이어
    const [mapMarkers, setMapMarkers] = useState([]); // 맵의 마커들
    const [selectedMarker, setSelectedMarker] = useState(); // [맵] 선택 센서
    const [popupOverlay, setPopupOverlay] = useState(); // [맵] 인포 창 오버레이
    const [viewCenter, setViewCenter] = useState(getCenter(MAP_EXTENT)); // 맵 센터

    const [selectedSensor, setSelectedSensor] = useState(); // [검색] 선택 센서
    const [selectedCtegory, setSelectedCtegory] = useState(""); // [검색] 선택 카테고리
    const [searchText, setSearchText] = useState(""); // [검색] 현재 검색어
    const [isOpen, setIsOpen] = useState(false); // [검색] 오픈 상태

    const [status, setStatus] = useState({all: 0, warning: 0, caution: 0, safety: 0, inspection: 0,}); // [상태] 갯수
    const [selectedStatusCtegory, setSelectedStatusCtegory] = useState(); // [상태] 선택 카테고리

    const popupRef = useRef(); 
    const searchRef = useRef(); 
    const statusRef = useRef();
    const svgRef = useRef();

    // 클러스터 아우터 원 색상
    const outerCircleFill = new Fill({
        color: 'rgb(113, 144, 224, 0.8)',
    });
    
    // 클러스터 이너 원 색상
    const innerCircleFill = new Fill({
        color: 'rgb(113, 144, 224, 0.8)',
    });

    // 클러스터 아우터 원 스타일
    const outerCircle = new CircleStyle({
        radius: 30,
        fill: outerCircleFill,
    });

    // 클러스터 이너 원 스타일
    const innerCircle = new CircleStyle({
        radius: 30,
        fill: innerCircleFill,
    });

    // 클러스터 원 텍스트 색
    const textFill = new Fill({
        color: '#00000',
    });

    // 클러스터 원 텍스트 스타일
    const textStroke = new Stroke({
        color: 'rgba(0, 0, 0, 0.6)',
        width: 3,
    });

    // 클러스터 마커 스타일
    function clusterMemberStyle(clusterMember) {
        const status = clusterMember.get('info').statusGroup;
        let markerSrc;
        
        if (status === 'safety') {
            markerSrc = require('../../../assets/img/map/sensorMarker.png');

        } else if (status === 'warning') {
            markerSrc = require('../../../assets/img/map/warningMarker.png');

        } else if (status === 'inspection') {
            markerSrc = require('../../../assets/img/map/inspectionMarker.png');

        } else if (status === 'caution') {
            markerSrc = require('../../../assets/img/map/cautionMarker.png');
        }

        return [
            new Style({
                image: new Icon({
                    crossOrigin: 'anonymous',
                    // scale: sensorData.search?.find(ele => ele.manageCd === clusterMember.get('manageCd')) ? 0.16 : 0.10,
                    scale: 0.40,
                    src: markerSrc,
                    img: undefined,
                    imgSize: undefined,
                }),
            }),
        ];
    }

    // 클러스터 결합 거리 조정 함수
    function clusterDistanceSet(map,popupOverlay) {
        const clusterSource = map.getAllLayers().find(ele => ele.get('key') === 'cluster')?.values_?.source;
        const zoom = map.getView().getZoom();

        if (!clusterSource) {
            return;
        }

        if (zoom < 10) {
            clusterSource.setDistance(130);
            popupOverlay.setPosition(null);
        } else {
            clusterSource.setDistance(0);
        }
    }

    // 클러스터 원 스타일
    function clusterStyle(size,zoom) {

        return [
            new Style({
            image: new CircleStyle({
                radius: 70 - zoom *2,
                fill: innerCircleFill,
            }),
            text: new Text({
                text: size.toString(),
                fill: textFill,
                font: `500 ${35 - zoom*2}px sans-serif`
            }),
                zindex: 200
            }),
        ];
    }

    // 클러스터 스타일 핸들러
    function clusterStyleHadler(feature) {
        const size = feature.get('features').length;
        const zoom = mapObject.map.getView().getZoom();

        if (size > 1 || zoom < 10) {
            feature.set('cluster',true);
            return clusterStyle(size,zoom);

        } else {
            const originalFeature = feature.get('features')[0];
            const data = clusterMemberStyle(originalFeature);
            return data
        }
    }

    // 지도 View 설정
    const setViewProp = (map, prop) => {
        map.setView(new View({
            projection: getProj('EPSG:3857'),
            center: viewCenter,
            extent: MAP_EXTENT,
            maxZoom: 25,
            ...prop,

            // zoom: zoom > 13 ? 13 : zoom,
        }))
    }

    // 마커로 이동
    const moveXY = (data,popup) => {

        setViewProp(mapObject.map,{ 
            center: new Point([data.x,data.y]).transform('EPSG:4326', 'EPSG:3857').flatCoordinates,
            zoom: 13,
        });

        const feature = mapMarkers.find(ele => ele.get('info').manageCd === data.manageCd);
        setSelectedMarker(feature.get('info'));

        const lonLat = feature.getGeometry().flatCoordinates;

        popup.setPosition(lonLat);
        popup.setOffset([25,-45])

        setSelectedStatusCtegory(null);
    }

    // 상단 카테고리 클릭 => 리스트 오픈
    const statusClick = ({target}) => {

        if (target.tagName !== 'LI' && target.tagName !== 'P') return;

        if (target.tagName === 'P') {
            target = target.closest('li');
        }

        if (selectedStatusCtegory === target.dataset.status) {
            setSelectedStatusCtegory(null);

        } else {
            setSelectedStatusCtegory(target.dataset.status);
        }

    }

    // 센서카드 클릭 => 디테일카드 오픈
    const sensorCardClick = (evt,data) => {
        if (evt.target.closest('.move_btn-wrap') && evt.target.closest('#search')) {
            return;
        }

        setSelectedSensor(data);
    }

    // 클러스터 생성
    const createCluster = (map,sensor) => {

        const vectorSource = new VectorSource();
        const addMarker = [];

        // Feature 생성(마커 클러스터, 원 클러스터)
        sensor.forEach(ele => {
            const point_feature = new Feature({
                geometry: new Point([ele.x, ele.y]).transform('EPSG:4326', 'EPSG:3857'),
                info: ele
            });
    
            vectorSource.addFeature(point_feature);
            addMarker.push(point_feature);
        })

        setMapMarkers(addMarker);

        // vectorSource를 clusterSource로 변경
        const clusterSource = new Cluster({
            attributions:
            'Data: <a href="https://www.data.gv.at/auftritte/?organisation=stadt-wien">Stadt Wien</a>',
            distance:100,
            source: vectorSource,
        });

        // VectorLayer 생성
        const clusters = new VectorLayer({
            source: clusterSource,
            // style: (feature) => {clusterStyleHadler(feature,map)},
            style: clusterStyleHadler,
            key:'cluster'
        });

        return clusters
    }

    // 기본 맵 객체 생성
    useEffect(() => {

        // 마커 인포창 오버레이 추가
        const popup = new Overlay({
            element: popupRef.current,
            positioning: 'bottom-center',
            stopEvent: false,
        });

        // 맵 기본 설정(범위지정, 기본좌표 등)
        const zoomToExtent = new ZoomToExtentCus({extent:MAP_EXTENT, label:'R',removePopup:() => popup.setPosition(null)});

        const map = new OlMap({
            controls: defaultControls().extend([
                    new ZoomSlider(),
                    zoomToExtent,
                    new SensorStatusControl({ref:statusRef}),
                    new SearchControl({setSearchData:setSearchData,ref:searchRef}),
                    new FullScreen(),
                ]),
            layers: [
                new TileLayer({
                    source: new OSM()
                }),
            ],

            target: "map",
        })

        setViewProp(map,{ 
            center: viewCenter,
            zoom: 7,
        });

        map.addOverlay(popup);
        setPopupOverlay(popup);
        
        // 맵 클릭 이벤트(마커 인포창 노출 이벤트)
        map.on('click', (evt) => {
            // console.log(new Point(map.getView().getCenter()).transform('EPSG:3857','EPSG:4326').flatCoordinates);
            // console.log(map.getView().calculateExtent(map.getSize()));

            setSelectedStatusCtegory(null);
            popup.setPosition(null);

            const feature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
                return feature;
            });

            if (!feature) {
              return;
            }

            if (feature.values_.features.length === 1 && !feature.get('cluster')) {
                const curFeature= feature.values_.features[0];

                setSelectedMarker(curFeature.get('info'));

                const lonLat = curFeature.getGeometry().flatCoordinates;
                popup.setPosition(lonLat);
                popup.setOffset([25,-45])

            } else {
                const coord = feature.getGeometry().flatCoordinates
                const zoom = map.getView().getZoom() + 6;

                setViewProp(map,{ 
                    center: coord,
                    zoom: zoom > 12 ? 12 : zoom,
                });
            }

        });

        map.on('pointermove', function (evt) {
            map.getTargetElement().style.cursor = map.hasFeatureAtPixel(evt.pixel)
              ? 'pointer'
              : '';
        });

        map.on('movestart', function (evt) {
            clusterDistanceSet(map,popup);
        });

        map.on('moveend', function (evt) {
            clusterDistanceSet(map,popup);
        });

        setMapObject({map:map});
    }, [])

    // 마커 생성
    useEffect(() => {
        if (mapObject.map) {

            // 상태현황 업데이트
            const tmp = {...status};
            Object.keys(tmp).forEach(ele => tmp[ele] = 0); 
            sensorData.all.forEach(ele => tmp[ele.statusGroup]++);
            tmp.all = sensorData.all.length;
            setStatus(tmp);

            // 기존 레이어 삭제
            mapObject.map.removeLayer(mapLayer)

            // 클러스터 레이어 생성
            const clusters = createCluster(mapObject.map,sensorData.all);

            // 맵에 클러스터 레이어 추가
            mapObject.map.addLayer(clusters);

            // 클러스터 레이어 훅에 저장
            setMapLayer(clusters);

            // 검색을 했을경우 해당 데이터 포커싱 및 표시
            if (sensorData.search?.length > 0 && reqCnt === 1) {
                // const boundXY = boundingExtent(sensorData.search.map(
                //         (ele) => {
                //             return new Point([ele.x,ele.y]).transform('EPSG:4326', 'EPSG:3857').flatCoordinates;
                //         }
                //     ));
                    
                // const centerXY = getCenter(boundXY);

                // console.log(boundXY)
                // console.log(new Point(boundXY))

                // setViewProp(mapObject.map,{ 
                //             center: centerXY,
                //             zoom: 7,
                //         });
            }

            // 클러스터 Distance 조정
            clusterDistanceSet(mapObject.map,popupOverlay);
        }
    }, [sensorData])

    useEffect(() => {
        const resizeHandler = () => {
            if (document.documentElement.clientWidth <= 580) {
                setDrag({mobile:true});
            }
        }

        window.addEventListener('resize',resizeHandler)

        return () => {
            window.removeEventListener('resize',resizeHandler)
        }
    },[])

    return (
        <>
            <div id="map" value={mapObject.map} style={{height: '100%', width: '100%', position: ''}}>

            </div>

            {/* 인포창 */}
            <ul 
                id="popup" 
                ref={popupRef} 
                onClick={(evt) => {
                    setReqCnt(1);
                    setSearchData({text:selectedMarker.manageCd,category:selectedMarker.statusGroup});
                    sensorCardClick(evt,selectedMarker)
                    setSearchText(selectedMarker.manageCd)
                    setIsOpen(true);
                }}
                onTouchEnd={(evt) => {
                    setReqCnt(1);
                    setSearchData({text:selectedMarker.manageCd,category:selectedMarker.statusGroup});
                    sensorCardClick(evt,selectedMarker)
                    setSearchText(selectedMarker.manageCd)
                    setIsOpen(true);
                }}
            >
                <li className="popup_manageCd">{selectedMarker?.manageCd}</li>
                <li className="popup_address">{selectedMarker?.address}</li>
                {/* <li className="popup_comment">{selectedMarker?.comment}</li> */}
                <li className={`popup_status ${selectedMarker?.statusGroup}`}>{selectedMarker?.status}</li>
            </ul>

            {/* 상태창 */}
            <ul id="status" ref={statusRef} onClick={statusClick}>
                <li data-status="all" className="all">전체<p>{sensorData.all?.length}</p></li>
                <li data-status="warning" className="warning">경보<p>{status.warning}</p></li>
                <li data-status="caution" className="caution">주의<p>{status.caution}</p></li>
                <li data-status="safety" className="safety">안전<p>{status.safety}</p></li>
                <li data-status="inspection" className="inspection">점검<p>{status.inspection}</p></li>
                <li className={status[selectedStatusCtegory] ? "status_sensors open" : "status_sensors"}>
                    <ul className="list">
                        {sensorData.all
                            ?.map(ele => {
                                if (selectedStatusCtegory !== 'all' && ele.statusGroup !== selectedStatusCtegory) return;

                                return (
                                <MapCard 
                                    onClick={(evt) => {
                                        setReqCnt(1);
                                        setSearchData({text:ele.manageCd,category:ele.statusGroup});
                                        sensorCardClick(evt,ele);
                                        setIsOpen(true);
                                        setSearchText(ele.manageCd)
                                    }} 
                                    key={ele.manageCd} 
                                    data={ele} 
                                    move={moveXY}
                                    popup={popupOverlay} 
                                    marker={selectedMarker}/>
                                )
                            })
                        }

                    </ul>
                    <div className="cancel mobile" onClick={() => {setSelectedStatusCtegory(null)}}>닫기</div>
                </li>
            </ul>

            {/* 검색 */}
            <div id="search" 
                className={isOpen ? `open ${drag.searchRef && 'drag'}`: ``} 
                ref={searchRef} 
                style={{...(drag.mobile ? {width : '100%'} : drag.width ? {width : `${drag.width}px`} : {width : `15%`})}
            }>
                <div className="search_main">
                    <div className="main_top" style={{...(drag?.size === 'sm' ? {fontSize:'1.2em'} : {fontSize:'1.5em'})}}>
                        <ul className="top_category" data-select="all" onClick={({target}) => {
                            if (target.tagName !== "LI") return;

                            setSelectedCtegory(target.dataset.status);
                            setSearchData({text:searchText,category:target.dataset.status});
                            setSelectedSensor(null);
                        }}
                        >
                            <li data-status="all" className={selectedCtegory === 'all' ? "all selected":"all"}>전체</li>
                            <li data-status="warning" className={selectedCtegory === 'warning' ? "warning selected":"warning"}>경보</li>
                            <li data-status="caution" className={selectedCtegory === 'caution' ? "caution selected":"caution"}>주의</li>
                            <li data-status="safety" className={selectedCtegory === 'safety' ? "safety selected":"safety"}>안전</li>
                            <li data-status="inspection" className={selectedCtegory === 'inspection' ? "inspection selected":"inspection"}>점검</li>
                        </ul>

                        <div className="top_search-input">
                            <input
                                type="text"
                                placeholder="검색어를 입력해주세요"
                                value={searchText}
                                onChange={({target}) => {
                                    setSearchText(target.value);
                                }}
                                onKeyPress={(e) => {
                                    if (e.key === "Enter") {
                                        setReqCnt(1);
                                        setSearchData({text:searchText,category:selectedCtegory});
                                        setSelectedSensor(null);
                                    }
                                }}
                            />
                            <div onClick={() => {
                                setReqCnt(1);
                                setSearchData({text:searchText,category:selectedCtegory});
                                setSelectedSensor(null);
                            }}>검색</div>
                        </div>
                    </div>

                    <ul className="main_body">
                        {selectedSensor && 
                            <MapCard 
                                data={selectedSensor} 
                                detailOn={true} 
                                back={() => {setSelectedSensor(null)}}    
                            />
                        }

                        {!selectedSensor && sensorData.search?.map(ele => {

                            return (
                                <MapCard 
                                    onClick={sensorCardClick} 
                                    key={ele.manageCd} 
                                    data={ele} 
                                    move={moveXY} 
                                    popup={popupOverlay} 
                                    drag={drag}/>
                                )
                        })}
                        {!sensorData.search && <div className="placeholder">검색어를 입력해주세요.</div>}
                        {sensorData.search && !sensorData.search.length && <div className="placeholder">데이터가 없습니다.</div>}
                    </ul>
                </div>

                <div className="search_utile">
                    <div className={`${isOpen ? `drag open ${drag.searchRef && 'on'}` : `drag`}`} onMouseDown={(e) => {
                        if (isOpen) {
                            const width = searchRef.current.getBoundingClientRect().width;
                            setDrag(
                                {   x:e.clientX,
                                    y:e.clientY,
                                    width:width,
                                    ...(width > 370 ? {size:'md'} : {size:'sm'}),
                                    searchRef:searchRef
                                }
                            );
                        }
                    }}></div>
                    <button 
                        onClick={() => {
                            setIsOpen(!isOpen);
                        }}
                        >
                        {isOpen ? 
                            <FontAwesomeIcon icon={faLeftLong} size="xl" color="#ffffff"/> :
                            <FontAwesomeIcon icon={faRightLong} size="xl" color="#ffffff"/>}
                    </button>
                </div>
            </div>
        </>
    )
}

export default Map;