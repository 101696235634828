import "./holidayStyle.css"
import React, { useEffect, useState, useRef, useContext } from "react";

import {responseMsg} from "../common/ResponseMsg";
import ContentModal from "./ContentModal";
import Modal from "../common/Modal";
import CusFullCalendar from "./CusFullCalendar";
import {Dropdown} from "primereact/dropdown";
import { ConfirmPopup } from 'primereact/confirmpopup';
import { confirmPopup } from 'primereact/confirmpopup';
import { InputText } from 'primereact/inputtext';
import { Fieldset } from 'primereact/fieldset';
import { Toast } from 'primereact/toast';

const nonSelect = {name:"선택안함", code: -1};

function Holiday({name}) {
    const [regionCodeList, setRegionCodeList] = useState([]);
    const [selectRegionLv1, setSelectRegionLv1] = useState({});
    const [selectRegionLv2, setSelectRegionLv2] = useState(nonSelect);
    const [selectRegionLv3, setSelectRegionLv3] = useState(nonSelect);
    const [holidayList, setHolidayList] = useState([]);
    const toast = useRef(null);

    const getHoliday = (regionCodeList) => {
        let lvCode;

        if (selectRegionLv3.code != -1) {
            lvCode = regionCodeList.find(code => code.id == selectRegionLv3.code);
        }

        else if (selectRegionLv2.code != -1) {
            lvCode = regionCodeList.find(code => code.id == selectRegionLv2.code);
        }

        else {
            lvCode = regionCodeList.find(code => code.depth == 1);
        }

        fetch(`/api/holiday?region=${lvCode.id}`, {
            method : 'get',
        })
        .then((res) => res.json())
        .then(json => {
            setHolidayList(json.data);
        })
    }

    useEffect(() => {
        fetch("/api/system/codes/REGION",{
            method:"GET"
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    const lv1 = json.data.find(ele => ele.depth == 1);

                    setRegionCodeList(json.data)
                    setSelectRegionLv1({name:"대한민국", code: lv1.id} );
                } else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch(error => {
                console.log(error)
            });
    },[])

    useEffect(() => {
        setSelectRegionLv3(nonSelect);
    },[selectRegionLv2])

    useEffect(() => {
        if (regionCodeList.length) {
            getHoliday(regionCodeList);
        }
    },[regionCodeList, selectRegionLv1, selectRegionLv2, selectRegionLv3])

    return (
        <div className={`contents`} id="holiday">
            <div className={`page_title`}>
                <div>{name}</div>
            </div>

            <div className="page_contents">
                <div className="top">
                    <Dropdown
                        value={selectRegionLv1}
                        options={
                            regionCodeList.filter(code => code.depth == 1).map(ele => {
                                return {name:"대한민국", code: ele.id}
                            })
                        }
                        onChange={(e) => {
                            setSelectRegionLv1(e.value);
                        }}
                        optionLabel="name"
                    />
                    <Dropdown
                        value={selectRegionLv2}
                        options={[
                            ...[nonSelect],
                            ...regionCodeList
                                .filter(code => code.depth == 2)
                                .map(ele => {
                                    return {name:ele.name, code: ele.id}
                                })
                        ]}
                        onChange={(e) => {
                            setSelectRegionLv2(e.value);
                        }}
                        optionLabel="name"
                    />
                    <Dropdown
                        value={selectRegionLv3}
                        options={[
                            ...[nonSelect],
                            ...regionCodeList
                                .filter(code => selectRegionLv2?.code == code.parent && code.depth == 3)
                                .map(ele => {
                                    return {name:ele.name, code: ele.id}
                                })
                        ]}
                        onChange={(e) => {
                            setSelectRegionLv3(e.value);
                        }}
                        optionLabel="name"
                    />
                </div>
                <CusFullCalendar
                    mode={'U'}
                    dayCellFontSize={14}
                    holidayList={holidayList}
                    dateClick={(event, date) => {
                        if (selectRegionLv2.code == -1 && selectRegionLv3.code == -1) {
                            alert("대한민국 공휴일은 수정할 수 없습니다.");
                            return;
                        }

                        let useYn = "Y";

                        let find = holidayList.find(ele => ele.holiday == date);

                        if (find) {
                            useYn = "N";
                        }

                        const dayTarget = event.target.closest('.fc-day')
                        let selectCode;

                        if (selectRegionLv3.code != -1) {
                            selectCode = selectRegionLv3.code
                        } else if (selectRegionLv2.code != -1) {
                            selectCode = selectRegionLv2.code
                        } else {
                            selectCode = selectRegionLv1.code
                        }

                        const accept = (calendarValue) => {
                            fetch("/api/holiday", {
                                method : 'post',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                body: JSON.stringify({holiday: date, region: selectCode, useYn: useYn, memo: calendarValue})
                            })
                                .then((res) => res.json())
                                .then(json => {
                                    if (useYn == "Y") {
                                        toast.current.show({ severity: 'success', summary: '등록 완료', detail: '일정이 추가됐습니다..', life: 3000 });

                                    } else {
                                        toast.current.show({ severity: 'success', summary: '삭제 완료', detail: '일정이 삭제됐습니다.', life: 3000 });

                                    }
                                    getHoliday(regionCodeList);
                                })
                        };

                        let calendarValue = "";

                        confirmPopup({
                            target: dayTarget,
                            message: useYn == "Y" ?
                                <div className="confirm_pop">
                                    <div className="date">{date}</div>
                                    <div className="message">일정을 등록하겠습니까?</div>
                                    <div className="input">
                                        <InputText style={{height:25}} onChange={(e) => {
                                            calendarValue = e.target.value
                                        }}/>
                                    </div>
                                </div>
                                :
                                <div className="confirm_pop">
                                    <div className="date">{date}</div>
                                    <div className="message">일정을 지우겠습니까?</div>
                                    <div className="input">
                                        <Fieldset legend="메모">
                                            <p className="m-0" style={{fontSize:14}}>
                                                {find.memo}
                                            </p>
                                        </Fieldset>
                                    </div>
                                </div>
                            ,
                            accept: () => accept(calendarValue),
                            reject: () => {}
                        });
                    }}
                />
            </div>

            <ConfirmPopup />
            <Toast ref={toast} />
        </div>
    );
}

export default Holiday;