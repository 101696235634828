import loginStyle from "./LoginStyle.module.css"
import React, { useEffect, useState, useContext } from "react";
import {Routes, Route, Navigate, useNavigate } from "react-router-dom";
import {responseMsg} from "../common/ResponseMsg";
import { useForm } from "react-hook-form";
import FindPassword from "./FindPassword";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faReply } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

function Login() {
    const [isLoginOpen,setLoginOpen] = useState(null);
    const [isPwOpen,setPwOpen] = useState(false);
    const {register, handleSubmit, formState: { isSubmitting, isDirty, errors }} = useForm();
    const navigate = useNavigate();

    const onSubmit = (formData) => {
        const formDataObj = new FormData();
        Object.keys(formData).forEach(ele => formDataObj.set(ele,formData[ele]))

        fetch("/api/login", {
            method : 'post',
            body: formDataObj
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.LOGIN_FAIL.code) {
                    Swal.fire({text:'로그인에 실패했습니다', icon: 'error',});

                } else if(json.code === responseMsg.LOGIN_SUCCESS.code) {
                    localStorage.setItem("USER",json.data);
                    navigate('/main');

                }  else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }

    return (
        <div className={loginStyle.login}>

            <div className={loginStyle.loginWrap}>
                <form 
                    className={isLoginOpen ? loginStyle.form_open : loginStyle.form} 
                    style={{
                        zIndex: `${isLoginOpen === null ? 3 : isLoginOpen ? 3 : 2}`
                    }}
                    onSubmit={handleSubmit(onSubmit)} 
                >

                    <div className={loginStyle.header}>
                        <span className={loginStyle.logo}></span>
                        <span className={loginStyle.title}>로그인</span>
                    </div>
                    <div className={loginStyle.body}>
                        <div className={loginStyle.inputGroup}>
                            <label className={loginStyle.label}>아이디</label>
                            <input
                                className={loginStyle.input}
                                type="text"
                                name="userId"
                                {...register("userId")}
                            />
                        </div>
                        <div className={loginStyle.inputGroup}>
                            <label className={loginStyle.label}>비밀번호</label>
                            <input
                                className={loginStyle.input}
                                type="password"
                                name="userPass"
                                {...register("userPass")}
                            />
                        </div>

                        <div style={{display:'flex',alignItems:'center'}}>
                            <label className={loginStyle.label} htmlFor="rememberMe">로그인 상태 유지</label>
                            <input
                                id="rememberMe"
                                type="checkbox"
                                name="remember-me"
                                {...register("remember-me")}
                            />
                        </div>
                    </div>

                    <div className={loginStyle.submitWrap}>
                        <button className={loginStyle.submit} type="submit">로그인</button>
                    </div>

                    <div className={loginStyle.switchWrap}>
                        <div><FontAwesomeIcon icon={faReply} rotation={180} color="#83878f"/></div>
                        <div className={loginStyle.switch} onClick={() => {
                            setPwOpen(true);
                            setLoginOpen(false);
                        }}>
                            비밀번호 재발급
                        </div>
                    </div>
                </form>

                <FindPassword
                    openState={{
                        isLoginOpen:isLoginOpen,
                        setLoginOpen:setLoginOpen,
                        isPwOpen:isPwOpen,
                        setPwOpen:setPwOpen
                    }}
                />

            </div>
        </div>
    );
}

export default Login;