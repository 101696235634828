export const responseMsg = {
    FAIL: {
        code:"-99",
        message:"응답에 문제가 생겼습니다."
    },
    SUCCESS: {
        code:"00",
        message:"응답이 정상적으로 이루어졌습니다."
    },
    UN_AUTHORIZED: {
        code:"1",
        message:"인증이 필요합니다."
    },
    ACCESS_DENIED: {
        code:"2",
        message:"접근권한이 없습니다."
    },
    LOGIN_FAIL: {
        code:"3",
        message:"로그인에 실패했습니다."
    },
    LOGIN_SUCCESS: {
        code:"4",
        message:"로그인에 성공했습니다."
    },
    TMP_PASSWORD_FAIL: {
        code:"5",
        message:"이메일 또는 아이디가 올바르지 않습니다."
    },
    USER_DUPL_ID: {
        code:"6",
        message:"아이디가 중복됩니다."
    },
    USER_DUPL_EMAIL: {
        code: "7",
        message:"이메일이 중복됩니다."
    },
    USER_DUPL_CELL_NO: {
        code:"8",
        message:"전화번호가 중복됩니다."
    },
    ALLIANCE_FOUND_USER: {
        code:"9",
        message:"해당 소속의 계정이 존재합니다."
    }
};

