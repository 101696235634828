import AuthStyle from './AuthStyle.module.css';
import React, { useEffect, useState, useContext } from "react";
import {Routes, Route, Navigate, useNavigate } from "react-router-dom";
import {responseMsg} from "../../common/ResponseMsg";
import {Checkbox} from 'primereact/checkbox';
import {Button} from "primereact/button";
import GridTable from "../../common/GridTable";
import Swal from "sweetalert2";

function AuthEditModal({selectedRole, rendering}) {
    const [checkBox, setCheckBox] = useState([]);

    const checkBoxRenderer = (cell) => {
        const col = cell.colDef;
        const data = cell.data;

        return (
            <div className='grid_check_box'>
                {data[col.field] !== null && <Checkbox
                    checked={data[col.field] ? true : false}
                    onChange={(event) => checkBoxChangeHandler(cell,event)}
                />}
            </div>
        )
    }

    const createMenusArr = (arr,menus) => {
        if (!menus) return;

        menus.forEach(ele => {
            arr.push(ele);
            createMenusArr(arr,ele.children);
        });
    }

    useEffect(() => {
        
        fetch("/api/system/menus",{
            method:"GET"
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    const menus = [];
                    createMenusArr(menus,json.data);

                    menus.forEach(ele => {
                        if (!ele.children.length) {
                            ele['modal_select'] = selectedRole?.menus.find(menu => menu.id === ele.id) ? true : false;
                            ele['modal_insert'] = selectedRole?.privileges.find(privilege => privilege.type === 'I' && privilege.menuId === ele.id) ? true : false;
                            ele['modal_update'] = selectedRole?.privileges.find(privilege => privilege.type === 'U' && privilege.menuId === ele.id) ? true : false;
                        } else {
                            ele['modal_select'] = null
                            ele['modal_insert'] = null
                            ele['modal_update'] = null
                        }
                    })

                    setCheckBox(menus);
            
                }  else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch((error) => {
                console.log(error)
            });
    },[])

    const gridConfig = {
        columnDefs: [
            { headerName: 'Level', field: 'depth', flex: 1,},
            { headerName: '시스템명', field: 'name', flex: 1,},
            { headerName: 'URL', field: 'url', flex: 3,},
            { headerName: '조회', field: 'modal_select', cellRenderer: checkBoxRenderer, flex: 1,},
            { headerName: '등록', field: 'modal_insert', cellRenderer: checkBoxRenderer, flex: 1,},
            { headerName: '수정', field: 'modal_update', cellRenderer: checkBoxRenderer, flex: 1,},
        ],
        pagination: false,
        rowData: checkBox,
        suppressRowHoverHighlight: true,
    };

    const onUpdateHandler = () => {
        const reqCheckBox = [];

        checkBox.forEach(ele => {
            ele['modal_select'] && reqCheckBox.push({menuId:ele.id, roleId:selectedRole.id, type: 'S'})
            ele['modal_insert'] && reqCheckBox.push({menuId:ele.id, roleId:selectedRole.id, type: 'I'})
            ele['modal_update'] && reqCheckBox.push({menuId:ele.id, roleId:selectedRole.id, type: 'U'})
        })

        fetch("/api/system/roles",{
            method:"PATCH",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(reqCheckBox),
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    Swal.fire("수정완료");
                    rendering();
                } else if (json.code === responseMsg.FAIL.code) {
                    alert("통신이 지연되고 있습니다..");
                }
            })
            .catch(error => {
                console.log(error)
            });
    }

    const checkBoxChangeHandler = (cell,event) => {
        const isChecked = event.target.checked ? true : false;

        const newMenus = [...checkBox].map(ele => {
            if (ele.id === cell.data.id) {
                ele[cell.colDef.field] = isChecked;
            }

            return ele;
        })

        setCheckBox(newMenus);
    }

    return (
        <div>
            <div className="top">
                <form className="input_wrap">
                    <div className="input_group">
                        <label>권한코드</label>
                        <input
                            className="text_input"
                            type="text"
                            disabled
                            defaultValue={selectedRole.name}
                        />
                    </div>

                    <div className="input_group">
                        <label>권한명</label>
                        <input
                            className="text_input"
                            type="text"
                            disabled
                            defaultValue={selectedRole.encodedNm}

                        />
                    </div>

                    <div className="input_group">
                        <label>Comment</label>
                        <input
                            className="text_input"
                            type="text"
                            disabled
                            defaultValue={selectedRole.comment}

                        />
                    </div>
                </form>
            </div>

            <div className="body">
                <GridTable config={gridConfig} type='modal'/>
            </div>

            <footer>
                <Button
                    label="취소"
                    severity="secondary"
                    raised
                    icon="pi pi-times"
                    size="sm"
                    onClick={rendering}
                />
                <Button
                    label="수정"
                    severity="success"
                    raised
                    icon="pi pi-check"
                    size="sm"
                    onClick={onUpdateHandler}
                />
            </footer>
        </div>
    );
}

export default AuthEditModal;