import React, { useEffect, useState, useContext } from "react";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"
import moment from "moment";
import koLocale from '@fullcalendar/core/locales/ko';

function CusFullCalendar({mode, holidayList, dateClick, dayCellFontSize}) {

    return (
        <>
            <FullCalendar
                plugins={[ dayGridPlugin, interactionPlugin ]}
                initialView="dayGridMonth"
                locale={koLocale}
                headerToolbar={{
                    today: '?'
                }}
                dateClick={(arg) => {
                    if (mode != "U") return;

                    dateClick(arg.jsEvent, arg.dateStr)
                }}
                events={holidayList.filter(ele => ele.memo).map(ele => {
                    return {date: ele.holiday, title: ele.memo, holiday: ele.holiday}
                })}
                eventContent={(arg) => {
                    const eventData = arg.event;

                    return (
                        <div onClick={(event) => {
                            if (mode != "U") return;

                            const date = moment(eventData.start).format("YYYY-MM-DD");
                            dateClick(event, date);
                        }} className="event_content">{eventData.title}</div>
                    )
                }}
                dayCellClassNames={(arg) => {
                    const dateText = moment(arg.date).format('YYYY-MM-DD');

                    if (holidayList.map(ele => ele.holiday).includes(dateText)) {
                        return 'holiday';
                    } else {
                        return '';
                    }
                }}
                dayCellContent={(arg) => {
                    const dateText = moment(arg.date).format("YYYY-MM-DD");
                    const isSaturday = moment(arg.date).day() === 6;

                    console.log(arg);

                    const isHoliday = holidayList
                        .map((ele) => ele.holiday)
                        .includes(dateText);

                    const dayCellStyle = {
                        color: isSaturday && isHoliday ? 'blue' : isHoliday ? "red" : "black",
                        fontWeight: 800,
                        fontSize: dayCellFontSize,
                    };

                    return (
                        <div
                            className={`fc-daygrid-day fc-day fc-day-${arg.dayNumber}`}
                            style={dayCellStyle}
                        >
                            <span className="fc-daygrid-day-top">{arg.dayNumberText.replace("일", "")}</span>
                            <span className="fc-daygrid-day-number">{arg.dayNumber}</span>
                        </div>
                    );
                }}
            />
        </>
    );
}

export default CusFullCalendar;