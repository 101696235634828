import React, { useEffect, useState, useRef } from "react";
import SensorStyle from "./SensorStyle.module.css";
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import ko from "date-fns/locale/ko"
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel, faDownload } from "@fortawesome/free-solid-svg-icons";
import { responseMsg } from "../common/ResponseMsg";
import CusFullCalendar from "../holiday/CusFullCalendar";
import { OverlayPanel } from 'primereact/overlaypanel';

function DetailSearchWrap(props) {
    const [render, setrRender] = useState(false);
    const [selectedInterval, setSelectedInterval] = useState("12hour");
    const [selectedRange, setSelectedRange] = useState("0");
    const [chartXTicks, setChartXTicks] = useState(1);
    // 현재 보여주는 컴포넌트 상태값
    const [selectedVisual, setSelectedVisual] = useState("table")
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DDTHH:mm"));
    const [endDate, setEndDate] = useState(moment().subtract(12, "hours").format("YYYY.MM.DD HH:mm:ss"));
    const [holidayList, setHolidayList] = useState([]);

    const holidayOp = useRef(null);

    const handleClick = () => {
        props.comeback();
        props.setSearchProps({});
    }

    const handleSearch = () => {
        setrRender(!render);
    }

    const excelDownload = () => {
        fetch(`/api/data/measurement/excel`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
              },
            body: JSON.stringify({
                imei:props.selectedRow.imei,
                startDate:moment(startDate).format("YYYY.MM.DD HH:mm:ss"),
                endDate:endDate,
                headers: props.detailGrid.filter(ele => ele.field && ele.headerName && !ele.notExcel).map(ele => {
                    return {key: ele.field, text: ele.headerName};
                })
            }),
        })
        .then((res) => res.blob())
        .then((blob) => {
            const href = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute(
                'download', 
                `${props.selectedRow.manageCd}_${moment(startDate).format("YYYY_MM_DD HH_mm")}~${moment(endDate).format("YYYY_MM_DD_ HH_mm_")}.xlsx`
                );
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
    }

    useEffect(() => {
        props.setSearchProps({
            interval:selectedInterval,
            range:selectedRange,
            startDate:startDate, 
            endDate:endDate,
            chartXTicks:chartXTicks
        });
    },[render])

    useEffect(() => {
        const id = props.selectedRow.region.id

        fetch(`/api/holiday?region=${id}`, {
            method : 'get',
        })
            .then((res) => res.json())
            .then(json => {
                setHolidayList(json.data);
            })
    },[])

    return(
        <div className={`btn_wrap grid_header block`}>
            <div className="search_wrap">
                <div className="option_wrap" key="referenceDate" data-key="referenceDate">
                    <div className="option_title">기준일</div>
                        <input
                            style={{width:'auto'}}
                            type="datetime-local"
                            className="text_input"
                            value={startDate}
                            onChange={({target}) => {
                                setStartDate(target.value)
                            }}
                        />
                    <div/>
                </div>
                <div className="option_wrap" key="gugan" data-key="gugan">
                    <div className="option_title">구간설정</div>
                    <ul className={SensorStyle.searchBox} data-select="" onClick={({target}) => {
                        if(target.tagName !== "LI") return;

                        setSelectedInterval(target.dataset.value);
                        setSelectedRange(target.dataset.range);
                        setChartXTicks(target.dataset.xTicks);
                        setEndDate(target.dataset.time);
                        setrRender(!render);
                    }}>
                        <li 
                            className={selectedInterval === "12hour" ? `12hour active` : `12hour`}
                            data-value="12hour" 
                            data-range="0" 
                            data-x-ticks={1}
                            data-time={`${moment(startDate).subtract(12, "hours").format("YYYY.MM.DD HH:mm:ss")}`}>12시간</li>

                        <li 
                            className={selectedInterval === "24hour" ? `24hour active` : `24hour`}
                            data-value="24hour" 
                            data-range="0" 
                            data-x-ticks={2}
                            data-time={`${moment(startDate).subtract(24, "hours").format("YYYY.MM.DD HH:mm:ss")}`}>24시간</li>

                        <li 
                            className={selectedInterval === "1week" ? `1week active` : `1week`}
                            data-value="1week" 
                            data-range="1" 
                            data-x-ticks={12}
                            data-time={`${moment(startDate).subtract(1, "weeks").format("YYYY.MM.DD HH:mm:ss")}`}>1주일</li>

                        <li 
                            className={selectedInterval === "1month" ? `1month active` : `1month`}
                            data-value="1month" 
                            data-range="6" 
                            data-x-ticks={12}
                            data-time={`${moment(startDate).subtract(1, "months").format("YYYY.MM.DD HH:mm:ss")}`}>1개월</li>

                        <li className={selectedInterval === "3month" ? `3month active` : `3month`}
                            data-value="3month" 
                            data-range="6" 
                            data-x-ticks={12}
                            data-time={`${moment(startDate).subtract(3, "months").format("YYYY.MM.DD HH:mm:ss")}`}>3개월</li>

                    </ul>
                </div>
                <div className="option_wrap" key="see" data-key="see">
                    <div className="option_title">시각화</div>
                    <ul className={SensorStyle.searchBox} data-select="" onClick={({target}) => {
                        if(target.tagName !== "LI") return;

                        setSelectedVisual(target.dataset.value);
                        props.setShowChart(target.dataset.value === "table" ? false : true);
                    }}>
                        <li data-value="table" className={selectedVisual === "table" ? `table active` : `table`}>Raw data</li>
                        <li data-value="chart" className={selectedVisual === "chart" ? `chart active` : `chart`}>graph</li>
                    </ul>
                </div>       
                {props.showChart ||
                    <div className="option_wrap">
                        <div className="option_title">다운로드</div>
                        <ul className={SensorStyle.searchBox} data-select="">
                            <li className="excel" onClick={excelDownload}>
                                <span>Excel</span>
                                <span><FontAwesomeIcon icon={faDownload}/></span>
                            </li>
                        </ul>
                    </div>  
                }
   
            </div>
            <div className="btn_wrap2">
                <button className="btn_holiday" onClick={(e) => holidayOp.current.toggle(e)}>달력</button>
                <button className="btn_back" onClick={handleClick}>뒤로가기</button>   
                <button className="btn_time_search" onClick={handleSearch}>검색</button>   
            </div>
            <OverlayPanel ref={holidayOp}>
                <div style={{
                    minWidth: 550,
                    maxWidth: 650,
                    fontSize: 12,
                }}>
                    <CusFullCalendar holidayList={holidayList} />
                </div>
            </OverlayPanel>
        </div>
    )
}

export default DetailSearchWrap;