import React, { useEffect, useState, useContext, useRef } from "react";
import {Routes, Route, Navigate, useNavigate } from "react-router-dom";
import {responseMsg} from "./ResponseMsg"
import { Menubar } from 'primereact/menubar';
import MyPage from "./MyPage";
import Modal from "./Modal";
import {CommonContext} from "../../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff, faUser } from "@fortawesome/free-solid-svg-icons";

function Header() {
    const navigate = useNavigate();
    const [sideMenu, setSideMenu] = useState([]);
    const [myModal, setMyModal] = useState({});
    const sideMenuNode = useRef();
    const myPages = useContext(CommonContext).myPages;
    const logout = useContext(CommonContext).logout;

     // 마이페이지 모달
     const openMyModal = (cell) => {

        setMyModal({
            ...myModal,
            open: true,
            close: closeMyModal,
            main: <MyPage closeMyModal={closeMyModal}/>,
        });
    };

    const closeMyModal = () => {

        setMyModal({
            ...myModal,
            open : false,
        });
    };

    const createChildMenu = (ele) => {

        const obj = {
            label: ele.name,
            icon: `pi pi-fw ${ele.ico}`,
        }

        if (ele.children.length) {
            obj.items = [];
        } else {
            obj.url = ele.url;
            obj.expanded = true;
        }  

        ele.children.map(ele2 => {
            obj.items.push(createChildMenu(ele2));
        })

        return obj;
    }

    useEffect(() => {

        setSideMenu(myPages.map(ele => {
            return createChildMenu(ele);
        }));

        sideMenuNode.current.addEventListener('click',(e) => {
            if (!e.target.closest('.p-menuitem a') || e.target.closest('.p-menuitem a').getAttribute('href') === '#') return;

            e.preventDefault();
            window.location.href = e.target.closest('.p-menuitem a').getAttribute('href');
        })

    },[])

    return (
        <div id="header">
            <div className="header_top">
                <div className="logo" onClick={() => {window.location.href = '/main'}}></div>

                <div className="side_menu card" ref={sideMenuNode}>
                    <Menubar model={sideMenu}/>
                </div>

                <ul className="top_menu">
                    <li
                        onClick={openMyModal}
                    >
                        <FontAwesomeIcon icon={faUser} color={'white'} size="xs"/>
                    </li>

                    <li
                        onClick={logout}
                    >
                        <FontAwesomeIcon icon={faPowerOff} color={'white'} size="xs"/>
                    </li>
                </ul>
            </div>

            <Modal
                headerText="내 정보"
                size="modal-lg"
                modal={myModal}
            >
            </Modal>
        </div>
    );
}

export default Header;