import SensorStyle from "./SensorStyle.module.css"
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { Button } from "primereact/button";
import Map from "./sensorMap/Map.js";
import { responseMsg } from "../common/ResponseMsg";
import moment from "moment";

function ContentModal(props) {
  const { mode, data, close } = props;
  const [ regionDepth1, setRegionDepth1 ] = useState([]);  
  const [ regionDepth2, setRegionDepth2 ] = useState([]);
  const [ selectedRegionDepth1, setSelectedRegionDepth1 ] = useState();
  const [ selectedRegionDepth2, setSelectedRegionDepth2 ] = useState();
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
    getValues,
    setValue,
  } = useForm({defaultValues: {...data, ...(data?.installDt && {installDt:moment(data.installDt).format('YYYY-MM-DDTHH:mm:ss')})}});
  
  useEffect(() => {
    const categorizedData = props.regions?.reduce((result, obj) => {
      if(obj.depth === 2){
        result.depth1.push(obj);
      } else if(obj.depth === 3) {
        result.depth2.push(obj);
      }

      return result;
    } , {depth1 : [], depth2: []})

    setSelectedRegionDepth1(mode === 'update' ? data.region.parent :categorizedData.depth1[0]?.id);
    setSelectedRegionDepth2(mode === 'update' ? data.region.id : categorizedData.depth2[0]?.id)
    setRegionDepth1(categorizedData.depth1);
    setRegionDepth2(categorizedData.depth2);

  }, [])

  const [readOnly, setReadOnly] = useState(false);

  // 삭제
  const btnDelete = (e) => {
    console.log(data)
     fetch(`/api/system/sensors/${data.imei}`, {
        method: "DELETE",
      })
      .then((res) => res.json())
      .then((json) => {
          if (json.code === responseMsg.SUCCESS.code) {
              Swal.fire("등록완료");
              close(false);
              props.rendering();
              
          }  else if (json.code === responseMsg.FAIL.code) {
              alert('통신이 지연되고 있습니다..');
          }
      })
      .catch((error) => {
          console.log(error)
      });

  }

  // 저장
  const onSubmit = (formData) => {
    
    if (mode === 'insert') {
      fetch("/api/system/sensors", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({...formData, region:selectedRegionDepth2}),
      })
      .then((res) => res.json())
      .then((json) => {
          if (json.code === responseMsg.SUCCESS.code) {
              Swal.fire("등록완료");
              close(false);
              props.rendering();
              
          }  else if (json.code === responseMsg.FAIL.code) {
              alert('통신이 지연되고 있습니다..');
          }
      })
      .catch((error) => {
          console.log(error)
      });

    } else if (mode === 'update'){
      fetch("/api/system/sensors", {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({...formData, region:selectedRegionDepth2}),
      })
      .then((res) => res.json())
      .then((json) => {
          if (json.code === responseMsg.SUCCESS.code) {
              Swal.fire("등록완료");
              close(false);
              props.rendering();
              
          }  else if (json.code === responseMsg.FAIL.code) {
              alert('통신이 지연되고 있습니다..');
          }
      })
      .catch((error) => {
          console.log(error)
      });
    }
  }

  // 좌표값 추출
  const getCoordinate = (value) => {
    setValue('x', value[0]);
    setValue('y', value[1]);
  }

  return (
    <div>
      <div className={SensorStyle.body}>
        <div className={SensorStyle.modalLeft}>
          <Map viewData={data ? data : null} clickEvent={getCoordinate}/>
        </div>

        <div className={SensorStyle.modalRight}>
          <form className={`input_wrap ${SensorStyle.form}`}>
            <div className="input_group">
              <label htmlFor="manageCd">관리번호</label>
              <input
                type="text"
                name="manageCd"
                className="text_input"
                {...register("manageCd", {
                  required: "관리번호를 입력해주세요.",
                })}
              />
            </div>
            <div style={{color:'red', textAlign:'end'}}>{errors['manageCd']?.message}</div>

            <div className="input_group">
              <label htmlFor="imei">IMEI</label>
              <input
                type="text"
                name="imei"
                className="text_input"
                {...register("imei",{required: "IMEI 값은 필수 입력입니다. (수정이 불가능하니 주의해주세요)"})}
                disabled={mode === 'update' ? true : false}
              />
            </div>
            <div style={{color:'red', textAlign:'end'}}>{errors['imei']?.message}</div>

            <div className="input_group">
              <label>지역</label>
              <select
                className="select_input"
                disabled={regionDepth1.length === 1}
                name="sido"
                value={selectedRegionDepth1}
                {...register("sido", {
                  onChange:(e) => {
                    setSelectedRegionDepth1(e.target.value);
                    setSelectedRegionDepth2(regionDepth2?.filter(ele => ele.parent == e.target.value)[0].id);
                  }
                })}
              >
                {regionDepth1?.map(ele => {

                  return (
                    <option key={ele.id} value={ele.id}>{ele.name}</option>
                  )
                })}
              </select>
            </div>

            <div className="input_group">
              <label>세부지역</label>
              <select
                className="select_input"
                disabled={regionDepth2.length === 1}
                name="region"
                value={selectedRegionDepth2}
                {...register("region", {
                  onChange:(e) => setSelectedRegionDepth2(e.target.value)
                })}
              >
                {regionDepth2?.filter(ele => ele.parent == selectedRegionDepth1)?.map(ele => {

                  return (
                    <option key={ele.id} value={ele.id}>{ele.name}</option>
                  )
                })}
              </select>
            </div>
          
            <div className="input_group">
              <label htmlFor="x">위도
              </label>
              <input
                type="text"
                name="x"
                className="text_input"
                {...register("x")}
              />
            </div>

            <div className="input_group">
              <label htmlFor="y">경도</label>
              <input
                type="text"
                name="y"
                className="text_input"
                {...register("y")}
              />
            </div>

            <div className="input_group">
              <label htmlFor="address">주소</label>
              <input
                type="text"
                name="address"
                className="text_input"
                {...register("address")}
              />
            </div>

            <div className="input_group">
              <label htmlFor="department">부서</label>
              <input
                type="text"
                name="department"
                className="text_input"
                {...register("department")}
              />
            </div>

            <div className="input_group">
              <label htmlFor="installDt">설치일</label>
              <input
                type="datetime-local"
                name="installDt"
                className="text_input"
                defaultValue={moment().format("YYYY-MM-DD HH:mm:ss")}
                {...register("installDt")}
              />
            </div>

            <div className="input_group">
              <label htmlFor="comment">비고</label>
              <input
                type="text"
                name="comment"
                className="text_input"
                {...register("comment")}
              />
            </div>
          </form> 
        </div>
      </div>

      <footer>
        <div className="btnWrap">
          <Button
            label="취소"
            severity="secondary"
            raised
            icon="pi pi-times"
            size="sm"
            onClick={close}
          />
          {mode === "update" ? (
            <Button
              label="삭제"
              severity="danger"
              raised
              icon="pi pi-trash"
              size="sm"
              onClick={btnDelete}
            />
          ) : null}
          <Button
            label= { mode === "update" ? "수정" : "등록"}
            size="sm"
            type="submit"
            icon="pi pi-check"
            severity= { mode ===  "update" ? "" : "success"}
            raised 
            onClick={handleSubmit(onSubmit)}
          />
        </div>
      </footer>     
    </div>

  );
}

export default ContentModal;
