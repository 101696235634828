import AllianceStyle from './AllianceStyle.module.css';
import React, { useEffect, useState, useContext } from "react";
import {Routes, Route, Navigate, useNavigate } from "react-router-dom";
import {responseMsg} from "../../common/ResponseMsg";
import GridTable from "../../common/GridTable";
import Modal from "../../common/Modal";
import AllianceAddModal from "./AllianceAddModal";
import AllianceEditModal from './AllianceEditModal';

function Alliance({name}) {
    const [render, setRender] = useState(false);
    const [searchObj, setSearchObj] = useState();
    const [regions, setRegions] = useState();
    const [alliances, setAlliances] = useState();
    const [selectedAlliance, setSelectedAlliance] = useState();
    const [selectedRegionCode1, setSelectedRegionCode1] = useState({});
    const [allianceAddModal, setAllianceAddModal] = useState({});
    const [allianceUpdateModal, setAllianceUpdateModal] = useState({});

    const rendering = () => {
        closeAllianceAddModal();
        closeAllianceUpdateModal();
        setRender(!render);
    }

    // 소속 모달 (등록)
    const openAllianceAddModal = (cell) => {

        setAllianceAddModal({
            ...allianceAddModal,
            open: true,
            close: closeAllianceAddModal,
            main: <AllianceAddModal
                rendering={rendering}
            />,
        });
    };

    const closeAllianceAddModal = () => {

        setAllianceAddModal({
            ...allianceAddModal,
            open : false,
        });
    };

    // 소속 모달 (수정)
    const openAllianceUpdateModal = (cell) => {

        setAllianceUpdateModal({
            ...allianceUpdateModal,
            open: true,
            close: closeAllianceUpdateModal,
            main: <AllianceEditModal
                alliance={cell.data}
                rendering={rendering}
            />,
        });
    };

    const closeAllianceUpdateModal = () => {

        setAllianceUpdateModal({
            ...allianceUpdateModal,
            open : false,
        });
    };

    const leftGridConfig = {
        columnDefs: [
            { headerName: '소속코드', field: 'allianceCode', flex: 0.2, minWidth: 200},
            { headerName: '소속명', field: 'allianceName', flex: 0.2, minWidth: 200},
            { headerName: '등록일', field: 'createDtm', flex: 0.3, minWidth: 200},
            { headerName: '수정일', field: 'updateDtm', flex: 0.3, minWidth: 200},
            { headerName: '사용여부', field: 'useYn', flex: 0.1, minWidth: 200},
        ],
        pagination: false,
        rowData: alliances,
        rowSelection: 'single',
        onCellClicked: (cell) => {
            setSelectedAlliance(cell.data);
        },
        onCellDoubleClicked: (cell) => openAllianceUpdateModal(cell),
    };

    const rightGridConfig = {
        columnDefs: [
            { headerName: '지역 권한 LV1', field: 'name', flex: 1, minWidth: 200, cellRenderer: (param) => {

                return `${param.value}(${selectedAlliance?.regions?.filter(ele => ele.parent === param.data.id).length || 0})`
            }},
        ],
        rowSelection: 'single',
        rowData: regions && [...new Set(selectedAlliance?.regions?.filter(ele => ele.depth === 3).map(ele => ele.parent))].map(ele => {

            return regions.find(ele2 => ele2.id == ele);
        }),
        onCellClicked: (cell) => {
            setSelectedRegionCode1(cell.data);
        },
    };

    const rightGridConfig2 = {
        columnDefs: [
            { headerName: '지역 권한 LV2', field: 'name', flex: 1, minWidth: 200,}
        ],
        suppressRowHoverHighlight: true,
        rowData: selectedAlliance?.regions?.filter(ele => ele.depth === 3 && ele.parent === selectedRegionCode1.id),
    };

    const apiGetAllianceList = (page) => {
        let url = '/api/system/alliances?'
        const pagingSize = 20;

        if (page) {
            url += `page=${page-1}&`
            url += `size=${pagingSize}&`
        }

        if(searchObj) {
            Object.keys(searchObj.req).forEach(ele => {
                if (searchObj.req[ele]) {
                    url += `${ele}=${searchObj.req[ele]}&`
                }
            })
            
            fetch(url ,{
                method:"GET"
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    setAlliances(json.data);
                    setSelectedAlliance(json.data[0]);

                }  else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch((error) => {
                console.log(error)
            });
        } 
    }

    useEffect(() => {
        apiGetAllianceList();
    },[render,searchObj])

    useEffect(() => {
        fetch("/api/system/codes/REGION/2",{
            method:"GET"
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    setRegions(json.data)

                } else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch(error => {
                console.log(error)
            });
    },[])

    return (
        <div className="contents">
            <div className="page_title">{name}</div>

            <div className="page_contents">

                <div className={AllianceStyle.left}>
                    <GridTable 
                        menu="ALLIANCE"
                        selectedRow={{row:selectedAlliance,check:"id"}}
                        header={{
                            add: openAllianceAddModal,
                            search: {
                                searchSetter:setSearchObj,
                                option:[
                                    {field:'allianceName',name:'소속명',default:""},
                                    {field:'useYn',name:'사용여부',select:[
                                        {name: '사용', value: 'Y'},
                                        {name: '미사용', value: 'N'}   
                                    ],default:"Y"},
                                ]
                            }
                        }}
                        config={leftGridConfig} 
                        block={true}
                    />
                </div>

                <div className={AllianceStyle.right}>
                    <GridTable 
                        menu="ALLIANCE"
                        header={true}
                        config={rightGridConfig} 
                        notSelected={true}
                        style={{ width: '100%', height: '100%' }}
                        block={true}
                    />
                </div>

                <div className={AllianceStyle.right}>
                    <GridTable 
                        menu="ALLIANCE"
                        header={true}
                        config={rightGridConfig2} 
                        style={{ width: '100%', height: '100%' }}
                        block={true}
                    />
                </div>
            </div>

            <Modal
                headerText="소속 등록"
                size="modal-md"
                modal={allianceAddModal}
            >
            </Modal>

            <Modal
                headerText="소속 수정"
                size="modal-md"
                modal={allianceUpdateModal}
            >
            </Modal>
        </div>
    );
}

export default Alliance;