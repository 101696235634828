import React, { useEffect, useState, useContext } from "react";
import {Routes, Route, Navigate, useNavigate } from "react-router-dom";

function Error404(){

    return (
        <div id="ErrorPage404" style={{color:'black'}}>
            Error 404
        </div>
    );
}

export default Error404;