import React, { useEffect, useState } from "react";
import Chart from "../common/Chart";
import SensorStyle from './SensorStyle.module.css';
import GridTable from "../common/GridTable";
import moment from "moment";
import { responseMsg } from "../common/ResponseMsg";
import { gridBadgeRender, getStatusGroupColor } from "../common/Common";

function DataChart(props) {
    const [ chartMeasurement, setChartMeasurement ] = useState([]);
    const [ minDate, setMinDate ] = useState(0);
    const [ maxDate, setMaxDate ] = useState(0);
    const [ ticks, setTicks ] = useState();
    const [ chart1, setChart1 ] = useState("line");
    const [ chart2, setChart2 ] = useState("line");
    const [ chart3, setChart3 ] = useState("line");
    const [ chart4, setChart4 ] = useState("line");
    const [ chart4info, setChart4info ] = useState({select:0 ,tab:[{dataKey:'speed',name:'최고속도'},{dataKey:'avgSpeed',name:'평균속도'}]});

    useEffect(() => {
        const tmp = {...props.searchProps, startDate:moment(props.searchProps.startDate).format("YYYY.MM.DD HH:mm:ss")}

        if (props.searchProps.interval) {
            fetch(`/api/system/sensors/chart/${props.selectedSensor.imei}/${tmp.startDate}/${tmp.endDate}?range=${props.searchProps.range}`, {
                method: "GET"
            })
            .then((res) => res.json())
            .then((json) => {
                if(json.code === responseMsg.SUCCESS.code) {
                    const ufcParseData = json.data.map(ele => {
                        return {...ele,create_dtm:moment.utc(ele.create_dtm).valueOf()};
                    })

                    setChartMeasurement(ufcParseData);

                    const ticks = [];

                    let min = json.data[json.data.length-1]?.create_dtm;
                    let max = json.data[0]?.create_dtm;

                    // ticks.push(moment.utc(min).valueOf());

                    while(true) {
                        min = moment(min).add(parseInt(props.searchProps.chartXTicks), 'hours').format("YYYY-MM-DDTHH:00:00");

                        if (moment.utc(min).valueOf() >= moment.utc(max).valueOf()) {
                            break;
                        } else {
                            ticks.push(moment.utc(min).valueOf());
                        }
                    }

                    // ticks.push(moment.utc(max).valueOf());

                    setTicks(ticks);
                }
            })
        }

    }, [props.searchProps])

    // 온도 차트
    const chart1Config = {
        id:'chart1',
        chart:chart1,
        xData: "create_dtm",
        referenceLineY:{
            y: '0',
            stroke: "#827E7E",
            fill: "#827E7E",
            label:"0°C"
        },
        YAxis: {
            type: "number",
            domain: [-20, 50],
            allowDataOverflow:true,
            allowDecimals: true,
            tickCount: 10,
        },
        XAxis: {
            type: "number",
            tickCount: 1,
            ...(ticks && {domain:[ticks[0],ticks[ticks.length-1]] ,ticks:ticks})
        },
        lineConfig:[
            {
                lineType: chart1,
                type: "monotone",
                dataKey: "temp",
                stroke: "red",
                fill: "red",
                name: "센서온도",
            },
            {
                lineType: chart1,
                type: "monotone",
                dataKey: "kmaTemp",
                stroke: "#5d97dc",
                fill: "#5d97dc",
                name: "기상청온도",
            },

        ],
        data:{},
    }

    // 습도 차트
    const chart2Config = {
        id:'chart',
        chart:chart2,
        xData: "create_dtm",
        YAxis: {
            type: "number",
            domain: [0, 100],
            allowDataOverflow:true,
            allowDecimals: true,
            tickCount: 10
        },
        XAxis: {
            type: "number",
            tickCount: 1,
            ...(ticks && {domain:[ticks[0],ticks[ticks.length-1]] ,ticks:ticks})
        },
        lineConfig:[
            {
                lineType: chart2,
                type: "monotone",
                dataKey: "humidity",
                stroke: "blue",
                fill: "blue",
                name: "센서습도",
            },
            {
                lineType: chart2,
                type: "monotone",
                dataKey: "kmaHumidity",
                stroke: "orange",
                fill: "orange",
                name: "기상청습도"
            },

        ],
        data:{}
    }

    // 풍속 차트
    const chart3Config = {
        id:'chart3',
        chart:chart3,
        xData: "create_dtm",
        YAxis: {
            type: "number",
            domain: [0, 30],
            allowDataOverflow:true,
            allowDecimals: true,
            tickCount: 10
        },
        XAxis: {
            type: "number",
            tickCount: 1,
            ...(ticks && {domain:[ticks[0],ticks[ticks.length-1]] ,ticks:ticks})
        },
        lineConfig:[
            {
                lineType: chart3,
                type: "monotone",
                dataKey: "windSpeed",
                stroke: "green",
                fill: "green",
                name: "센서풍속",
            },
            {
                lineType: chart3,
                type: "monotone",
                dataKey: "kmaWindSpeed",
                stroke: "orange",
                fill: "orange",
                name: "기상청풍속"
            },

        ],
        data:{}
    }

     // 최고속도 차트
     const chart4Config = {
        id:'chart4',
        chart:chart4,
        xData: "create_dtm",
        YAxis: {
            type: "number",
            domain: [0, 100],
            allowDataOverflow:true,
            allowDecimals: true,
            tickCount: 10
        },
        XAxis: {
            type: "number",
            tickCount: 1,
            ...(ticks && {domain:[ticks[0],ticks[ticks.length-1]] ,ticks:ticks})
        },
        lineConfig:[
            {
                lineType: chart4,
                type: "monotone",
                dataKey: chart4info.tab[chart4info.select].dataKey,
                stroke: chart4 == 'bar' ? null : "#4a848c",
                fill: "#4a848c",
                name: chart4info.tab[chart4info.select].name,
            },
        ],
        data:{}
    }

    // 수집데이터 테이블
    const [tableConfig, setTableConfig] = useState([
        {headerName: '수집일', field: 'insert_dt', flex: 1},
        {headerName: '수집시간', field: 'insert_time', flex: 1},
        {headerName: '상태', field: 'status', flex: 1, cellRenderer: (props) => gridBadgeRender(props.value, getStatusGroupColor(props.data.statusGroup),"white")}
    ]);

    const gridConfig = {
        columnDefs: tableConfig,
        pagination: false,
        suppressRowHoverHighlight: true,
        rowData: chartMeasurement.map((ele) => {

            return {
                ...ele,
                insert_dt:moment.utc(ele.create_dtm).format("YYYY-MM-DD"),
                insert_time:moment.utc(ele.create_dtm).format("HH:mm"),
            }
        }),
    }


    return (
        <>
            { props.customBtnWrap && ( props.customBtnWrap ) }
            <div className={SensorStyle.contents}>      
                <div className={SensorStyle.left}>                    
                    <div className={SensorStyle.chart}>
                        <Chart config={chart1Config} data={chartMeasurement} title="기온 (°C)" setChart={setChart1}/>
                    </div>
                    <div className={SensorStyle.chart}>
                        <Chart config={chart2Config} data={chartMeasurement} title="습도 (%)" setChart={setChart2}/>
                    </div>
                </div>
                <div className={SensorStyle.mid}>
                    <div className={SensorStyle.chart}>
                        <Chart config={chart3Config} data={chartMeasurement} title="풍속 (m/s)" setChart={setChart3}/>
                    </div>
                    <div className={SensorStyle.chart}>
                            <Chart config={chart4Config} data={chartMeasurement} chartInfo={chart4info} setChartInfo={setChart4info} setChart={setChart4}/>
                    </div>
                </div>    

                <div className={SensorStyle.right}>
                    <div className="chart_title">History</div>
                    <GridTable config={gridConfig} data={chartMeasurement} style={{fontSize:'1.1em'}}/>
                </div>    
            </div>
        </>
    );
}

export default DataChart;