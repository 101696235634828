import React, { useEffect, useState, useMemo, useContext } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { CommonContext } from "../../../App.js";
import { responseMsg } from "../../common/ResponseMsg";
import { useForm, Controller } from "react-hook-form";
import { Button } from "primereact/button";
import Swal from "sweetalert2";

function UserAddModal({ rendering }) {
    const [roles, setRoles] = useState([]);
    const [alliance, setAlliance] = useState([]);
    const [selectedAlliance, setSelectedAlliance] = useState();
    const [selectedRole, setSelectedRole] = useState();
    const { register, handleSubmit, reset, formState: { isSubmitting, isDirty, errors } } = useForm();

    useEffect(() => {

        fetch("/api/system/roles", {
            method: "GET"
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    setSelectedRole(json.data[0].id)
                    setRoles(json.data)

                } else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch((error) => {
                console.log(error)
            });

        fetch("/api/system/alliances", {
            method: "GET"
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    setAlliance(json.data)
                    setSelectedAlliance(json.data[0].id)

                } else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch((error) => {
                console.log(error)
            });

    }, [])

    const autoHyphen = (target) => {
        target.value = target.value
            .replace(/[^0-9]/g, '')
            .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/(\-{1,2})$/g, "");
    }

    const onSubmitHandler = (formData) => {

        fetch("/api/system/users", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...formData, allianceId: selectedAlliance, roleId: selectedRole }),
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    Swal.fire("등록완료");
                    rendering();
                    
                } else if (json.code === responseMsg.USER_DUPL_ID.code) {
                    alert(responseMsg.USER_DUPL_ID.message);

                } else if (json.code === responseMsg.USER_DUPL_EMAIL.code) {
                    alert(responseMsg.USER_DUPL_EMAIL.message);

                } else if (json.code === responseMsg.USER_DUPL_CELL_NO.code) {
                    alert(responseMsg.USER_DUPL_CELL_NO.message);

                } else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }

    return (
        <div>
            <form className="input_wrap" >
                <div className="input_group">
                    <label>아이디</label>
                    <div className='input'>
                        <input
                            className="text_input"
                            type="text"
                            name="userId"
                            {...register("userId", {
                                required: "아이디를 입력해주세요.",
                            })}
                        />
                        {errors.userId?.type == 'required' &&
                            <div className="error_message">
                                <p>{errors.userId?.message}</p>
                            </div>
                        }
                    </div>
                </div>
                <div className="input_group">
                    <label>비밀번호</label>
                    <div className='input'>
                        <input
                            className="text_input"
                            type="text"
                            name="password"
                            {...register("password", {
                                required: "비밀번호를 입력해주세요.",
                            })}
                        />
                        {errors.password?.type == 'required' &&
                            <div className="error_message">
                                <p>{errors.password?.message}</p>
                            </div>
                        }
                    </div>
                </div>
                <div className="input_group">
                    <label>사용자명</label>
                    <div className='input'>
                        <input
                            className="text_input"
                            type="text"
                            name="userNm"
                            {...register("userNm", {
                                required: "사용자명을 입력해주세요.",
                            })}
                        />
                        {errors.userNm?.type == 'required' &&
                            <div className="error_message">
                                <p>{errors.userNm?.message}</p>
                            </div>
                        }
                    </div>
                </div>
                <div className="input_group">
                    <label>부서</label>
                    <div className='input'>
                        <input
                            className="text_input"
                            type="text"
                            name="department"
                            {...register("department")}
                        />
                    </div>
                </div>
                <div className="input_group">
                    <label>전화번호</label>
                    <div className='input'>
                        <input
                            className="text_input"
                            type="tel"
                            name="cellNo"
                            maxLength="13"
                            {...register("cellNo", {
                                required: "전화번호를 입력해주세요.",
                                onChange: ({target}) => autoHyphen(target)
                            })}
                        />
                        {errors.cellNo?.type == 'required' &&
                            <div className="error_message">
                                <p>{errors.cellNo?.message}</p>
                            </div>
                        }
                    </div>
                </div>
                <div className="input_group">
                    <label>이메일</label>
                    <div className='input'>
                        <input
                            className="text_input"
                            type="text"
                            name="email"
                            {...register("email", {
                                required: "이메일을 입력해주세요.",
                            })}
                        />
                        {errors.email?.type == 'required' &&
                            <div className="error_message">
                                <p>{errors.email?.message}</p>
                            </div>
                        }
                    </div>
                </div>
                <div className="input_group">
                    <label>소속</label>
                    <div className='input'>
                        <select
                            className="select_input"
                            disabled={alliance.length === 1}
                            name="allianceId"
                            value={selectedAlliance}
                            {...register("allianceId", {
                                onChange: (e) => setSelectedAlliance(e.target.value)
                            })}
                        >
                            {alliance?.map(ele => {

                                return (
                                    <option key={ele.id} value={ele.id}>{ele.allianceName}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                <div className="input_group">
                    <label>권한</label>
                    <div className='input'>

                        <select
                            className="select_input"
                            name="roleId"
                            value={selectedRole}
                            {...register("roleId", {
                                onChange: (e) => setSelectedRole(e.target.value)
                            })}
                        >
                            {roles.map(ele => {

                                return (
                                    <option key={ele.id} value={ele.id}>{ele.encodedNm}</option>
                                )
                            })}
                        </select>
                    </div>
                </div>
                <div className="input_group">
                    <label>비고</label>
                    <div className='input'>
                        <input
                            className="text_input"
                            type="text"
                            name="comment"
                            {...register("comment")}
                        />
                    </div>
                </div>
            </form>

            <footer>
                <Button
                    label="취소"
                    severity="secondary"
                    raised
                    icon="pi pi-times"
                    size="sm"
                    onClick={rendering}
                />
                <Button
                    label="등록"
                    severity="success"
                    raised
                    icon="pi pi-check"
                    size="sm"
                    onClick={handleSubmit(onSubmitHandler)}
                />
            </footer>
        </div>
    );
}

export default UserAddModal;