import { Tag } from 'primereact/tag';

// 그리드 테이블 기본 텍스트 배찌
export const gridBadgeRender = (text,backColor,color) => {
    
    return (
        <div style={{
                display:"flex",
                justifyContent:"center",
                alignItems:"center",
                width:"100%",
                height:"100%",
            }}>
            <Tag value={text} style={{backgroundColor:backColor,color:color}}></Tag>
        </div>
    
    )
}

export const getStatusGroupColor = (statusGroup) => {
    if (statusGroup === 'warning') {
        return "#e71818"

    } else if (statusGroup === 'caution') {
        return "#ca9011"

    } else if (statusGroup === 'safety') {
        return "#1a6b13"
        
    } else if (statusGroup === 'inspection') {
        return "#1053fc"
    }
}

export const logingStart = (statusGroup) => {

}

export const logingEnd = (statusGroup) => {

}