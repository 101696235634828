
import UserStyle from './UserStyle.module.css';
import React, { useEffect, useState, useContext } from "react";
import {Routes, Route, Navigate, useNavigate } from "react-router-dom";
import {responseMsg} from "../../common/ResponseMsg";
import GridTable from "../../common/GridTable";
import UserEditModal from "./UserEditModal";
import UserAddModal from "./UserAddModal";
import SmsModal from './SmsModal';
import Modal from "../../common/Modal";

function User({name}) {
    const [render, setRender] = useState(false);
    const [searchObj, setSearchObj] = useState();
    const [users, setUsers] = useState();
    const [updateModal, setUpdateModal] = useState({});
    const [addModal, setAddModal] = useState({});
    const [pagination, setPagination ] = useState();

    const rendering = () => {
        closeUpdateModal();
        closeAddModal();
        setRender(!render);
    }

    // 컬럼 기본 옵션
    const [columnDefs, setColumnDefs] = useState([
        { headerName: '번호', field: 'rowNo',flex:0.1,minWidth:100,},
        { headerName: '아이디', field: 'userId',flex:0.3,minWidth:100,},
        { headerName: '사용자명', field: 'userNm',flex:0.3,minWidth:100,},
        { headerName: '소속', field: 'allianceName',flex:0.3,minWidth:100,},
        { headerName: '부서', field: 'department',flex:0.3,minWidth:100,},
        { headerName: '전화번호', field: 'cellNo',flex:0.3,minWidth:100,},
        { headerName: '이메일', field: 'email',flex:0.3,minWidth:100,},
        { headerName: '등록일', field: 'createDtm',flex:0.3,minWidth:100,},
        { headerName: '수정일', field: 'updateDtm',flex:0.3,minWidth:100,},
        { headerName: '비고', field: 'comment',flex:0.5,minWidth:100,},
    ]);

    const gridConfig = {
        columnDefs: columnDefs,
        onCellDoubleClicked: (cell) => openUpdateModal(cell),
        rowSelection: 'single',
        rowData: users,
    };

    // 모달 (등록)
    const openAddModal = (cell) => {

        setAddModal({
            ...addModal,
            open: true,
            close: closeAddModal,
            main: <UserAddModal
                rendering={rendering}
            />
        });
    };

    const closeAddModal = () => {

        setAddModal({
            ...addModal,
            open : false,
        });
    };

    // 모달 (수정)
    const openUpdateModal = (cell) => {

        setUpdateModal({
            ...updateModal,
            open: true,
            close: closeUpdateModal,
            main: <UserEditModal
                user={cell.data}
                rendering={rendering}
            />
        });
    };

    const closeUpdateModal = () => {

        setUpdateModal({
            ...updateModal,
            open : false,
        });
    };

    const apiGetUserList = (page) => {
        let url = '/api/system/users?'
        const pagingSize = 20;

        if (page) {
            url += `page=${page-1}&`
            url += `size=${pagingSize}&`
        }

        if(searchObj) {
            Object.keys(searchObj.req).forEach(ele => {
                if (searchObj.req[ele]) {
                    url += `${ele}=${searchObj.req[ele]}&`
                }
            })
            
            fetch(url ,{
                method:"GET"
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    setUsers(json.data.map((ele,idx) => ({rowNo:idx+1, ...ele})) )
                    setPagination(json.pagination);
    
                } else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch((error) => {
                console.log(error)
            });
        } 
    }

    useEffect(() => {
      apiGetUserList(1);
      
    },[render,searchObj])

    return (
        <div className="contents">
            <div className="page_title">{name}</div>

            <div className="page_contents">
                <div className={UserStyle.center}>
                    <GridTable 
                        menu="USER"
                        pagination={pagination}
                        pageClick={apiGetUserList}
                        colChange={true}
                        chageColumnDefs={setColumnDefs}
                        header={{
                            add: openAddModal,
                            search: {
                                searchSetter:setSearchObj,
                                option:[
                                    {field:'userId',name:'아이디',default:''},
                                    {field:'userNm',name:'사용자명',default:''},
                                    {field:'useYn',name:'사용여부',select:[
                                        {name: '사용', value: 'Y'},
                                        {name: '미사용', value: 'N'}   
                                    ],default:'Y'},
                                ]
                            }
                        
                        }}
                        config={gridConfig} 
                        block={true}
                    />
                </div>
            </div>

            <Modal
                headerText="사용자 등록"
                size="modal-md"
                modal={addModal}
            >
            </Modal>

            <Modal
                
                headerText="사용자 수정"
                size="modal-md"
                modal={updateModal}
            >
            </Modal>
        </div>
    );
}

export default User;