import SensorStyle from "./SensorStyle.module.css"
import React, { useEffect, useState } from "react";
import {responseMsg} from "../common/ResponseMsg";
import GridTable from "../common/GridTable";
import Modal from "../common/Modal";
import ContentModal from "./ContentModal";
import { Button } from "primereact/button";
import SensorSearchWrap from "./SensorSearchWrap";
import DetailComponent from "./DetailComponent";
import DetailSearchWrap from "./DetailSearchWrap"
import { useSearchParams } from 'react-router-dom';
import moment from "moment";

function Sensor({name}) {
    const [render, setRender] = useState(false);
    const [searchObj, setSearchObj] = useState();
    const [sensor, setSensor] = useState();
    const [modalTitle, setModalTitle] = useState();
    const [modalOption, setModalOption] = useState({});
    const [modalSize, setModalSize] = useState();
    const [showDetail, setShowDetail] = useState(false); // 상세페이지 표출 여부
    const [showChart, setShowChart] = useState(false); // 센서 상세페이지 차트, 테이블 표출 여부
    const [selectedRow, setSelectedRow] = useState(); // 상세보기시 선택된 센서
    const [regions, setRegions] = useState([]); // 지역권한 리스트
    const [LogSearchProps, setLogSearchProps] = useState({});
    const [detailGrid, setDetailGrid] = useState();
    const [pagination, setPagination ] = useState();
    const [sort, setSort] = useState();
    const [searchParams, setSearchParams] = useSearchParams();

    const componetRendering = () => {
        setRender(!render);
    }

    // 조회버튼 렌더링
    const buttonRenderer = (props) => {
        return (
            <div style={{width:'100%'}}>
                <button className="btn_move" onClick={() => { 
                    setShowDetail(true); 
                    setSelectedRow(props.data);
                }} >이동</button>               
            </div>
        )
    }

    const apiGetSearchSensor = (page) => {
        let url = '/api/system/sensors/paging?'
        const pagingSize = 20;

        if (page) {
            url += `page=${page-1}&`
            url += `size=${pagingSize}&`
        }

        if(searchObj) {
            Object.keys(searchObj.req).forEach(ele => {
                if (searchObj.req[ele]) {
                    url += `${ele}=${searchObj.req[ele]}&`
            }
        })

        if (sort) {
            sort.forEach(ele => {
                url += `sort=${ele.header},${ele.sort}&`
            })
        }

            fetch(url, {
                method:"GET",
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    setSensor(json.data);
                    setPagination(json.pagination);

                }  else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch((error) => {
                console.log(error)
            });
        } 
    }

    // getSensorList
    useEffect(() => {
        const paramIMEI = searchParams.get("IMEI")

        if (paramIMEI) {
            fetch(`/api/system/sensors/${paramIMEI}`, {
                method:"GET",
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    setSelectedRow(json.data);
                    setShowDetail(true); 

                }  else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch((error) => {
                console.log(error)
            });

        } else {
            apiGetSearchSensor(1);
        }

    }, [render,searchObj,sort])

    // getRegionList
    useEffect(() => {
        fetch("/api/system/codes/REGION", {
          method: "GET"
        })
        .then((res) => res.json())
        .then((json) => {
          if(json.code === responseMsg.SUCCESS.code) {
            setRegions(json.data)
          }
        })
    }, [render])

    /* 항목 검색시 테이블 렌더링 */
    // 컬럼 기본 옵션
    const [columnDefs, setColumnDefs] = useState([
        { headerName: '관리번호', field: 'manageCd',flex:0.2,sortable:true, unSortIcon: true},
        { headerName: '지역', field: 'region.name',flex:0.2,minWidth:250, cellRenderer:({data}) => `${data.region.parentCode.name}[${data.region.name}]`},
        { headerName: '주소', field: 'address',flex:0.2},
        { headerName: '부서', field: 'department',flex:0.1,minWidth:100, },
        { headerName: '설치일', field: 'installDt',flex:0.2, sortable:true, unSortIcon: true},
        { headerName: 'IMEI', field: 'imei',flex:0.2,},        
        { headerName: '데이터이력', field: '',flex:0.2,cellRenderer: buttonRenderer,},
    ]);

    // 테이블 옵션
    const gridConfig = {
        columnDefs: columnDefs,
        rowData: sensor,
        rowSelection: 'single',
        onCellDoubleClicked: (cell) => cellClickedListener(cell),
    }

    /* 모달 */
    // insert modal open
    const onOpenModal = () => {
        
        setModalTitle("센서관리");
        setModalSize("modal-lg");
        setModalOption({
            ...modalOption,
            open: true,
            close: closeModal,
            main: <ContentModal mode={"insert"} close={closeModal} regions={regions} rendering={componetRendering}/>
        })
    };

    // modal close
    const closeModal = () => {
        setModalOption({
            ...modalOption,
            open: false,
        });
    };

    // update modal open
    const cellClickedListener = (cell) => {
        if(cell.colDef.field === '') { return }
        setModalTitle("센서관리");
        setModalSize("modal-lg");
        setModalOption({
            ...modalOption,
            open: true,
            close: closeModal,
            main: <ContentModal mode={"update"} data={cell.data} close={closeModal} regions={regions} rendering={componetRendering}/>
        })
    };

    /* 기타기능 */ 
    // 뒤로가기
    const comeback = () => {
        setShowDetail(false);
        setShowChart(false);
        setSearchObj(null);
        setSearchParams({});
    }

    return (
        <div className="contents">
            <div className={`page_title ${SensorStyle.title}`}>
                <div className={SensorStyle.titleContents1} style={{cursor:"pointer"}} onClick={() => window.location.href = '/sensor'}>{name}</div>
                <div className={SensorStyle.titleContents2}>
                    <div>{showDetail ? selectedRow.manageCd : ''}</div>
                    <div>{showDetail ? selectedRow.address : ''}</div>
                </div>
                <div className={"holiday_page"}>

                </div>
            </div>

            <div className="page_contents">
                <div className={ `sensor_content ${showChart ? 'chart' : ''}`}>
                    {!showDetail && <GridTable 
                        menu="SENSOR"
                        pagination={pagination}
                        pageClick={apiGetSearchSensor}
                        colChange={true}
                        chageColumnDefs={setColumnDefs}
                        header={{
                            add: onOpenModal,
                            search: {
                                searchSetter:setSearchObj,
                                sortSetter:setSort,
                                option:[
                                    {field:'address',name:'주소',default:""},
                                    {field:'department',name:'담당부서',default:""},
                                ],
                                dateOption: {
                                    type:'range',
                                    name:'설치일',
                                    start: {field:"startDate",default:'',dateFormat:'YYYY.MM.DD HH:mm:ss'},
                                    end: {field:"endDate",default:'',dateFormat:'YYYY.MM.DD HH:mm:ss'},
                                }
                            },
                        }}
                        config={gridConfig} 
                        block={true}
                    />}

                    {showDetail && <DetailComponent
                        data = {selectedRow}
                        showChart = {showChart}
                        searchProps = {LogSearchProps}
                        setDetailGrid = {setDetailGrid}
                        customBtnWrap = {<DetailSearchWrap 
                            selectedRow = {selectedRow} 
                            comeback = {comeback} 
                            setShowChart = {setShowChart}
                            showChart = {showChart} 
                            setSearchProps={setLogSearchProps}
                            detailGrid={detailGrid}
                        />}
                        block={true}
                    />} 
                </div>
            </div>

            <Modal
                headerText={modalTitle}
                size={modalSize}
                modal={modalOption}       
            />
        </div>
    )
}

export default Sensor;