import AuthStyle from './AuthStyle.module.css';
import React, { useEffect, useState, useContext } from "react";
import {Routes, Route, Navigate, useNavigate } from "react-router-dom";
import {responseMsg} from "../../common/ResponseMsg";
import {useForm} from "react-hook-form";
import {Button} from "primereact/button";
import Swal from "sweetalert2";

function AuthAddModal({rendering}) {
    const { register, handleSubmit, formState: { isSubmitting, isDirty, errors }} = useForm();

    const onSubmitHandler = (formData) => {

        fetch("/api/system/roles",{
            method:"POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    Swal.fire("등록완료");
                    rendering();
                } else if (json.code === responseMsg.FAIL.code) {
                    alert("통신이 지연되고 있습니다..");
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <div>
            <form className="input_wrap">
                <div className="input_group">
                    <label>권한ID</label>
                    <div className='input'>
                        <input
                            className="text_input"
                            placeholder="'ROLE_' 텍스트를 제외한 이름을 입력해주세요."
                            type="text"
                            name="name"
                            {...register("name",{
                                required: "권한ID를 입력해주세요.",
                            })}
                        />
                        {errors.name?.type == 'required' && 
                            <div className="error_message">
                                <p>{errors.name?.message}</p>
                            </div>
                        } 
                    </div>    
                </div>


                <div className="input_group">
                    <label>권한명</label>
                    <div className='input'>
                        <input
                            className="text_input"
                            type="text"
                            name="encodedNm"
                            {...register("encodedNm",{
                                required: "권한명을 입력해주세요.",
                            })}
                        />
                        
                        {errors.encodedNm?.type == 'required' && 
                            <div className="error_message">
                                <p>{errors.encodedNm?.message}</p>
                            </div>
                        }
                    </div>    
                </div>

                <div className="input_group">
                    <label>Comment</label>
                    <div className='input'>
                        <input
                            className="text_input"
                            type="text"
                            name="comment"
                            {...register("comment")}
                        />
                    </div>
                </div>
            </form>

            <footer>
                <Button
                    label="취소"
                    severity="secondary"
                    raised
                    icon="pi pi-times"
                    size="sm"
                    onClick={rendering}
                />
                <Button
                    label="추가"
                    severity="success"
                    raised
                    icon="pi pi-check"
                    size="sm"
                    onClick={handleSubmit(onSubmitHandler)}
                />
            </footer>

        </div>
    );
}

export default AuthAddModal;