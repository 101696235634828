import React, { useEffect, useState, useContext } from "react";
import {Routes, Route, Navigate, useNavigate } from "react-router-dom";
import {responseMsg} from "../common/ResponseMsg";
import { useForm } from "react-hook-form";
import contactStyle from "./ContactStyle.module.css"
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/panel';
import { Accordion, AccordionTab } from 'primereact/accordion';
import Swal from "sweetalert2";

function Contact({name}) {
    const { register, handleSubmit, formState: { isSubmitting, isDirty, errors }} = useForm();
    const navigate = useNavigate();
    const [selectedInquiries, setSelectedInquiries] = useState('');

    const onSubmit = (formData) => {

        fetch("/api/contact", {
            method : 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({...formData,inquiries:selectedInquiries})
        })
            .then((res) => res.json())
            .then((json) => {
                if(json.code === responseMsg.SUCCESS.code) {
                    Swal.fire('답변은 해당 이메일로 전달됩니다.');
                    navigate('/main');
                } else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }

    const errorHandle = (error) => {
        if (!selectedInquiries) setSelectedInquiries('required');
    }

    return (
        <div className={contactStyle.contact}>
            <div className="page_title">{name}</div>

            <div className={contactStyle.contents}>
                <div className={contactStyle.info}>
                    <ul>
                        <li><span>Tel</span><p>1644-2094</p></li>
                        <li><span>Fax</span><p>070-4152-4750</p></li>
                        <li><span>Email</span><p>stn11@stnroad.co.kr</p></li>
                        <li><span>Address</span><p><a href="https://map.naver.com/p/search/%EA%B2%BD%EA%B8%B0%EB%8F%84%20%EC%95%88%EC%96%91%EC%8B%9C%20%EB%8F%99%EC%95%88%EA%B5%AC%20%ED%9D%A5%EC%95%88%EB%8C%80%EB%A1%9C%20415/address/14134562.76929,4493838.3468096,%EA%B2%BD%EA%B8%B0%EB%8F%84%20%EC%95%88%EC%96%91%EC%8B%9C%20%EB%8F%99%EC%95%88%EA%B5%AC%20%ED%9D%A5%EC%95%88%EB%8C%80%EB%A1%9C%20415,new?searchType=address&isCorrectAnswer=true&c=18.00,0,0,0,dh">경기도 안양시 동안구 흥안대로 415 두산벤처다임614, 615호</a></p></li>
                    </ul>
                </div>

                <form className="flex flex-column gap-2">
                    <div className={contactStyle.inputWrap}>
                        <div className={contactStyle.inputBox}>
                            <div className="flex flex-column gap-2">
                                <label className={contactStyle.label} htmlFor="userName">이름</label>
                                <InputText
                                    id="userName"
                                    name="userName"
                                    style={{width:'100%', height: '37px'}}
                                    {...register("userName",{
                                        required: "이름을 입력해주세요.",
                                    })}
                                />
                            </div>
                            <div className="error_message">
                                {errors.userName?.type == 'required' && <p>{errors.userName?.message}</p>}
                            </div>
                        </div>

                        <div className={contactStyle.inputBox}>
                            <div className="flex flex-column gap-2">
                                <label className={contactStyle.label} htmlFor="userTel">휴대전화</label>
                                <InputText
                                    id="userTel"
                                    name="userTel"
                                    style={{width:'100%', height: '37px'}}
                                    {...register("userTel",{
                                        required: "휴대전화를 입력해주세요.",
                                    })}
                                />
                            </div>
                            <div className="error_message">
                                {errors.userTel?.type == 'required' && <p>{errors.userTel?.message}</p>}
                            </div>
                        </div>

                        <div className={contactStyle.inputBox}>
                            <div className="flex flex-column gap-2">
                                <label className={contactStyle.label} htmlFor="userEmail">이메일</label>
                                <InputText
                                    id="userEmail"
                                    name="userEmail"
                                    style={{width:'100%', height: '37px'}}
                                    {...register("userEmail",{
                                        required: "이메일을 입력해주세요.",
                                    })}
                                />
                            </div>
                            <div className="error_message">
                                {errors.userEmail?.type == 'required' && <p>{errors.userEmail?.message}</p>}
                            </div>
                        </div>

                        <div className={contactStyle.inputBox}>
                            <div className="flex flex-column gap-2">
                                <label className={contactStyle.label} htmlFor="inquiries">문의항목</label>
                                <Dropdown 
                                    inputId="inquiries" 
                                    value={selectedInquiries} 
                                    onChange={(e) =>setSelectedInquiries(e.value)}
                                    options={[
                                        { name: '고장신고', value:'고장신고'},
                                        { name: '데이터요청', value:'데이터요청'},
                                        { name: '설치문의', value:'설치문의'},
                                        { name: '기타', value:'기타'},
                                    ]} 
                                    optionLabel="name" 
                                    className="w-full md:w-14rem" 
                                    style={{width:'100%', height: '37px'}}
                                />
                            </div>
                            <div className="error_message">
                                {selectedInquiries == 'required' && <p>문의항목을 선택해주세요.</p>}
                            </div>
                        </div>

                        <div className={contactStyle.inputBox}>
                            <div className="flex flex-column gap-2">
                                <label className={contactStyle.label} htmlFor="request">요청사항</label>
                                <InputTextarea 
                                    id="request"
                                    name="request"
                                    rows={5} 
                                    cols={30} 
                                    style={{width:'100%'}}
                                    {...register("request",{
                                        required: "비밀번호는 필수 입력입니다.",
                                    })}
                                />
                            </div>
                            <div className="error_message">
                                {errors.request?.type == 'required' && <p>{errors.request?.message}</p>}
                            </div>
                        </div>

                        <div className={contactStyle.agreement}>
                            <Panel header="개인정보 수집 및 이용 동의">
                                <p className="m-0" style={{lineHeight:'25px',fontSize:"0.8em",fontWeight:"bold",color:"#585859c2"}}>
                                    개인정보 수집 이용 동의서<br/>
                                    1. 개인정보 수집, 이용 기관명: (주)에스티엔컴퍼니<br/>
                                    2. 개인정보 수집, 이용 범위와 사용목적<br/>
                                     - 수집, 이용 범위: 이름, 연락처, 이메일<br/>
                                     - 사용목적: 고객문의 답변 안내
                                </p>
                            </Panel>
                        </div>

                        <div className={contactStyle.inputBox}>
                            <div className={contactStyle.agreementCheck}>
                                <label htmlFor="agreementCheck">개인정보 수집 및 이용에 동의합니다.</label>
                                <input
                                    type="checkbox"
                                    id="agreementCheck"
                                    name="agreementCheck"
                                    {...register("agreementCheck",{
                                        required: "개인정보 수집 및 이용에 동의해주세요.",
                                    })}
                                />
                            </div>
                            <div className="error_message">
                                {errors.agreementCheck?.type == 'required' && <p>{errors.agreementCheck?.message}</p>}
                            </div>
                        </div>
                    </div>

                    <div className={contactStyle.submit}>
                        <Button type="submit" label="전 송" onClick={handleSubmit(onSubmit,errorHandle)}/>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Contact;