import React, { useEffect, useState, useContext, useMemo } from "react";
import {Routes, Route, Navigate, useNavigate } from "react-router-dom";
import {responseMsg} from "../common/ResponseMsg";
import {useForm} from "react-hook-form";
import {Button} from "primereact/button";
import {Checkbox} from 'primereact/checkbox';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLockOpen, faLock } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";

function MyPage({closeMyModal}) {
    const [severUser, setSeverUser] = useState({});
    const [passwordClose, setPasswordClose] = useState(true);
    const [regions, setRegions] = useState([]);
    const [selectedRegion1, setSelectedRegion1] = useState({});
    const [passwordVal, setPasswordVal] = useState('');
    const { register, handleSubmit, reset, formState: { isSubmitting, isDirty, errors }} = useForm(useMemo(() => {
        return {...severUser,...severUser.alliance,...severUser.role};
      }, [severUser]));  
      
    const userId = localStorage.getItem("USER");
    
    const onSubmit = (formData) => {
        let {department,userNm,cellNo,password,email} = formData;

        password = passwordVal || null;

        fetch(`/api/system/users/${userId}`,{
            method:"PATCH",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({department,userNm,cellNo,password,email}),
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    Swal.fire('성공적으로 수정됐습니다.')
                    closeMyModal();
                }  else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch((error) => {
                console.log(error)
            });

    }

    const autoHyphen = (target) => {
        target.value = target.value
            .replace(/[^0-9]/g, '')
            .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/(\-{1,2})$/g, "");
    }

    useEffect(() => {

        fetch(`/api/system/users/${userId}`,{
            method:"GET"
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    setSeverUser(json.data);
                    reset({...json.data,...json.data.alliance,...json.data.role});
                } else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch((error) => {
                console.log(error)       
            });
    },[reset])

    useEffect(() => {
        fetch("/api/system/codes/REGION",{
            method:"GET"
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    setRegions(json.data)
                    setSelectedRegion1(json.data.filter(ele => ele.depth == 2)[0])
                } else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch(error => {
                console.log(error)
            });
    },[])

    return (
        <div id="myPage">
            <form className="input_wrap">

                <div className="input_group">
                    <label>아이디</label>
                    <input
                        className="text_input"
                        type="text"
                        name="userId"
                        disabled
                        {...register("userId")}

                    />
                </div>

                <div className="input_group">
                    <label>소속</label>
                    <input
                        className="text_input"
                        type="text"
                        name="allianceName"
                        disabled
                        {...register("allianceName")}

                    />
                </div>

                <div className="input_group">
                    <label>부서</label>
                    <input
                        className="text_input"
                        type="text"
                        name="department"
                        {...register("department")}

                    />
                </div>

                <div className="input_group">
                    <label>이름</label>
                    <input
                        className="text_input"
                        type="text"
                        name="userNm"
                        {...register("userNm")}

                    />
                </div>

                <div className="input_group">
                    <label>연락처</label>
                    <input
                        className="text_input"
                        type="text"
                        name="cellNo"
                        maxLength="13"
                        {...register("cellNo",{
                            onChange:({target}) => autoHyphen(target)
                        })}

                    />
                </div>

                <div className="input_group">
                    <label>비밀번호</label>
                    <div className="lock" onClick={() => {
                            setPasswordVal('');
                            setPasswordClose(!passwordClose);
                        }}>
                        {passwordClose ?
                         <FontAwesomeIcon icon={faLock}/> :
                         <FontAwesomeIcon icon={faLockOpen}/>
                        }
                    </div>
                    <input
                        className="text_input"
                        type="password"
                        name="password"
                        value={passwordVal}
                        disabled={passwordClose}
                        placeholder={passwordClose ? '잠금을 해제하면 변경 가능합니다.' : '비밀번호를 입력해주세요.'}
                        {...register("password",{
                            onChange: ({target}) => setPasswordVal(target.value)
                        })
                        }

                    />
                </div>

                <div className="input_group">
                    <label>이메일</label>
                    <input
                        className="text_input"
                        type="text"
                        name="email"
                        {...register("email")}

                    />
                </div>

                <div className="input_group">
                    <label>권한</label>
                    <input
                        className="text_input"
                        type="text"
                        name="encodedNm"
                        disabled
                        {...register("encodedNm")}

                    />
                </div>

                <div className="input_group">
                    <label>시/도 권한</label>
                    
                    <div className="check_input">
                        {regions?.filter(ele => ele.depth === 2)?.map(ele => {
                         
                        const children =  severUser?.alliance?.regions.filter(ele2 => ele2.parent == ele.id);

                        if (!children || !children.length) return null;

                        return (
                            <div 
                                key={ele.id} 
                                className={`check_box_wrap ${selectedRegion1.id === ele.id && 'selected'}`}
                                onClick={() => setSelectedRegion1(ele)}
                                style={{cursor:"pointer"}}
                            >
                                <label htmlFor={ele.id} style={{cursor:"pointer"}}>{`${ele.name}(${children.length})`}</label>
                            </div>
                        )
                        })}
                    </div>

                </div>

                <div className='input_group'>
                    <label>군/구 권한</label>

                    <div className="check_input" style={{display:"block",height:'120px'}}>
                        {severUser.alliance?.regions?.filter(ele => ele.depth === 3 && ele.parent === selectedRegion1.id)?.map(ele => {

                            return (
                                <div key={ele.id} className="check_box_wrap">
                                    <label htmlFor={ele.id}>{ele.name}</label>
                                </div>
                            )
                        })}
                    </div>
                </div>

            </form>

            <footer>
                <Button
                    label="취소"
                    severity="secondary"
                    raised
                    icon="pi pi-times"
                    size="sm"
                    onClick={closeMyModal}
                />
                <Button
                    label="변경"
                    severity="success"
                    raised
                    icon="pi pi-check"
                    size="sm"
                    onClick={handleSubmit(onSubmit)}
                />
            </footer>
        </div>
    );
}

export default MyPage;
