import CodeStyle from "./CodeStyle.module.css";
import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import {Routes, Route, Navigate, useNavigate } from "react-router-dom";
import {responseMsg} from "../../common/ResponseMsg";
import GridTable from "../../common/GridTable";
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import Modal from "../../common/Modal";
import CodeAddModal from "./CodeAddModal";
import CodeEditModal from "./CodeEditModal";

function Code({name}) {
    const [render, setRender] = useState(false);
    const [searchObj, setSearchObj] = useState();
    const [codes, setCodes] = useState();
    const [selectedCode1, setSelectedCode1] = useState();
    const [selectedCode2, setSelectedCode2] = useState();
    const [updateModal, setUpdateModal] = useState({});
    const [addModal, setAddModal] = useState({});

    const rendering = () => {
        closeUpdateModal();
        closeAddModal();
        setRender(!render);
    }

    const leftGridConfig = {
        columnDefs: [
            { headerName: '코드', field: 'groupCode', flex: 1, minWidth: 100},
            { headerName: '코드명', field: 'name', flex: 1, minWidth: 100},
            { headerName: '비고', field: 'comment', flex: 1, minWidth: 100},
            { headerName: '사용여부', field: 'useYn', flex: 1, minWidth: 100},
        ],
        pagination: false,
        onCellClicked: (cell) => {
            setSelectedCode1(cell.data);
            setSelectedCode2(codes.find(ele => ele.parent === cell.data.id));
        },
        rowSelection: 'single',
        rowData: codes?.filter(ele => ele.depth == 1),
    };

    const centerGridConfig = {
        columnDefs: [
            { headerName: '코드', field: 'id', flex: 1, minWidth: 100},
            { headerName: '코드명', field: 'name', flex: 1, minWidth: 100},
            { headerName: '비고', field: 'comment', flex: 1, minWidth: 100},
            { headerName: '사용여부', field: 'useYn', flex: 1, minWidth: 100},
        ],
        pagination: false,
        onCellClicked: (cell) => {
            setSelectedCode2(cell.data);
        },
        onCellDoubleClicked: (cell) => openUpdateModal(cell),
        rowSelection: 'single',
        rowData: codes?.filter(ele => selectedCode1?.id === ele.parent),
    };

    const rightGridConfig = {
        columnDefs: [
            { headerName: '코드', field: 'id', flex: 1, minWidth: 200},
            { headerName: '코드명', field: 'name', flex: 1, minWidth: 200},
            { headerName: '비고', field: 'comment', flex: 1, minWidth: 200},
            { headerName: '사용여부', field: 'useYn', flex: 1, minWidth: 200},
        ],
        pagination: false,
        onCellDoubleClicked: (cell) => openUpdateModal(cell),
        rowSelection: 'single',
        rowData: codes?.filter(ele => selectedCode2?.id === ele.parent),
    };

    // 모달 (등록)
    const openAddModal = (type) => {

        setAddModal({
            ...addModal,
            headerText: `${type} 코드 등록`,
            open: true,
            close: closeAddModal,
            main: <CodeAddModal
                type={type}
                selectedCode1={selectedCode1}
                selectedCode2={selectedCode2}
                rendering={rendering}
            />
        });
    };

    const closeAddModal = () => {

        setAddModal({
            ...addModal,
            open : false,
        });
    };

    // 모달 (수정)
    const openUpdateModal = (cell) => {

        setUpdateModal({
            ...updateModal,
            open: true,
            close: closeUpdateModal,
            main: <CodeEditModal
                code={cell.data}
                rendering={rendering}
            />
        });
    };

    const closeUpdateModal = () => {

        setUpdateModal({
            ...updateModal,
            open : false,
        });
    };

    const apiGetCodeList = (page) => {
        let url = '/api/system/codes?'
        const pagingSize = 20;

        if (page) {
            url += `page=${page-1}&`
            url += `size=${pagingSize}&`
        }

        if(searchObj) {
            Object.keys(searchObj.req).forEach(ele => {
                if (searchObj.req[ele]) {
                    url += `${ele}=${searchObj.req[ele]}&`
                }
            })
            
            fetch(url ,{
                method:"GET"
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    setCodes(json.data)
    
                    if (!selectedCode1) {
                        const code1 = json.data.find(ele => ele.depth == 1);
                        setSelectedCode1(code1);
                        setSelectedCode2(json.data.find(ele => ele.parent == code1.id));
    
                    } else {
                        setSelectedCode1(json.data.find(ele => selectedCode1.id === ele.id));
                        setSelectedCode2(json.data.find(ele => selectedCode2.id === ele.id));
                    }
    
                }  else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch((error) => {
                console.log(error)
            });
        } 
    }


    useEffect(() => {
        apiGetCodeList();

    },[render,searchObj])

    return (
        <div className="contents">
            <div className="page_title">{name}</div>

            <div className="page_contents">

                <div className={CodeStyle.left}>
                    <GridTable 
                        menu="CODE"
                        selectedRow={{row:selectedCode1,check:"id"}}
                        header={
                            {search: {
                                searchSetter:setSearchObj,
                                option:[
                                    {field:'name',name:'코드명',default:""},
                                    {field:'useYn',name:'사용여부',select:[
                                        {name: '사용', value: 'Y'},
                                        {name: '미사용', value: 'N'}   
                                    ],default:"Y"},
                                ]}
                        }}
                        config={leftGridConfig} 
                        block={true}
                    />
                </div>

                <div className={CodeStyle.center}>
                    <GridTable 
                        menu="CODE"
                        selectedRow={{row:selectedCode2,check:"id"}}
                        header={{
                            type: 'Level 2',
                            add: openAddModal,
                        }}
                        config={centerGridConfig} 
                        block={true}
                    />
                </div>

                <div className={CodeStyle.right}>
                    <GridTable 
                        menu="CODE"
                        header={{
                            type: 'Level 3',
                            add: openAddModal,
                        }}
                        config={rightGridConfig} 
                        block={true}
                    />
                </div>
            </div>

            <Modal
                headerText="코드 등록"
                size="modal-md"
                modal={addModal}
            >
            </Modal>

            <Modal
                headerText="코드 수정"
                size="modal-md"
                modal={updateModal}
            >
            </Modal>
        </div>
    );
}

export default Code;