import UserStyle from './UserStyle.module.css';
import React, { useEffect, useState, useContext } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { responseMsg } from "../../common/ResponseMsg";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import GridTable from "../../common/GridTable";
import { useForm } from "react-hook-form";
import { Optional } from "ag-grid-community";
import SmsModal from './SmsModal';
import Modal from "../../common/Modal";
import Swal from "sweetalert2";

function UserEditModal({ user,rendering }) {
    const [input, setInput] = useState();
    const [roles, setRoles] = useState([]);
    const [alliance, setAlliance] = useState([]);
    const [selectedAlliance, setSelectedAlliance] = useState();
    const [selectedRole, setSelectedRole] = useState();
    const [smsModal, setSmsModal] = useState({});
    const { register, handleSubmit, formState: { isSubmitting, isDirty, errors } } = useForm({
        defaultValues: user
    });

    // 모달 (SMS)
    const openSmsModal = (cell) => {

        setSmsModal({
            ...smsModal,
            open: true,
            close: closeSmsModal,
            main: <SmsModal 
                userId={user.userId}
                closeSmsModal={closeSmsModal}
            />
        });
    };

    const closeSmsModal = () => {

        setSmsModal({
            ...smsModal,
            open : false,
        });
    };

    const onUpdateHandler = (formData) => {
        fetch("/api/system/users", {
            method: "PATCH",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    Swal.fire("수정완료");
                    rendering();

                } else if (json.code === responseMsg.USER_DUPL_EMAIL.code) {
                    alert(responseMsg.USER_DUPL_EMAIL.message);

                } else if (json.code === responseMsg.USER_DUPL_CELL_NO.code) {
                    alert(responseMsg.USER_DUPL_CELL_NO.message);

                } else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }

    const onDeleteHandler = () => {
        fetch(`/api/system/users/${user.userId}`, {
            method: "DELETE",
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    Swal.fire("삭제완료");
                    rendering();

                } else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }

    const autoHyphen = (target) => {
        target.value = target.value
            .replace(/[^0-9]/g, '')
            .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/(\-{1,2})$/g, "");
    }
    
    useEffect(() => {

        fetch("/api/system/roles", {
            method: "GET"
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    setSelectedRole(user.roleId)
                    setRoles(json.data)

                } else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch((error) => {
                console.log(error)
            });

        fetch("/api/system/alliances", {
            method: "GET"
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    setSelectedAlliance(user.allianceId)
                    setAlliance(json.data)

                } else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }, [])

    return (
        <div>
            <form className="input_wrap">
                <div className="input_group">
                    <label>아이디</label>
                    <div className='input'>
                        <input
                            className="text_input"
                            type="text"
                            name="userId"
                            disabled
                            {...register("userId", {
                                required: "아이디를 입력해주세요.",
                            })}

                        />
                        {errors.userId?.type == 'required' &&
                            <div className="error_message">
                                <p>{errors.userId?.message}</p>
                            </div>
                        }
                    </div>
                </div>

                <div className="input_group">
                    <label>사용자명</label>
                    <div className='input'>
                        <input
                            className="text_input"
                            type="text"
                            name="userNm"
                            {...register("userNm", {
                                required: "사용자명을 입력해주세요.",
                            })}

                        />
                        {errors.userNm?.type == 'required' &&
                            <div className="error_message">
                                <p>{errors.userNm?.message}</p>
                            </div>
                        }
                    </div>
                </div>

                <div className="input_group">
                    <label>부서</label>
                    <div className='input'>
                        <input
                            className="text_input"
                            type="text"
                            name="department"
                            {...register("department")}
                        />
                    </div>
                </div>

                <div className="input_group">
                    <label>전화번호</label>
                    <div className='input'>
                        <input
                            className="text_input"
                            type="tel"
                            name="cellNo"
                            maxLength="13"
                            {...register("cellNo", {
                                required: "전화번호를 입력해주세요.",
                                onChange: ({target}) => autoHyphen(target) 
                            })}

                        />
                        {errors.cellNo?.type == 'required' &&
                            <div className="error_message">
                                <p>{errors.cellNo?.message}</p>
                            </div>
                        }
                    </div>
                </div>

                <div className="input_group">
                    <label>이메일</label>
                    <div className='input'>
                        <input
                            className="text_input"
                            type="text"
                            name="email"
                            {...register("email", {
                                required: "이메일을 입력해주세요.",
                            })}

                        />
                        {errors.email?.type == 'required' &&
                            <div className="error_message">
                                <p>{errors.email?.message}</p>
                            </div>
                        }
                    </div>
                </div>

                <div className="input_group">
                    <label>소속</label>
                    <div className='input'>
                        <select
                            className="select_input"
                            disabled={alliance.length === 1}
                            name="allianceId"
                            value={selectedAlliance}
                            {...register("allianceId", {
                                onChange: (e) => setSelectedAlliance(e.target.value)
                            })}
                        >
                            {alliance?.map(ele => {

                                return (
                                    <option key={ele.id} value={ele.id}>{ele.allianceName}</option>
                                )
                            })

                            }
                        </select>
                    </div>
                </div>

                <div className="input_group">
                    <label>권한</label>
                    <div className='input'>
                        <select
                            className="select_input"
                            name="roleId"
                            value={selectedRole}
                            {...register("roleId", {
                                onChange: (e) => setSelectedRole(e.target.value)
                            })}
                        >
                            {roles?.map(ele => {

                                return (
                                    <option key={ele.id} value={ele.id}>{ele.encodedNm}</option>
                                )
                            })

                            }
                        </select>
                    </div>
                </div>

                <div className="input_group">
                    <label>비고</label>
                    <div className='input'>
                        <input
                            className="text_input"
                            type="text"
                            name="comment"
                            defaultValue={user.comment}
                            {...register("comment")}
                        />
                    </div>
                </div>

                <div className="input_group">
                    <label>사용여부</label>
                    <div className='input'>
                        <select
                            className="select_input"
                            name="useYn"
                            defaultValue="Y"
                            {...register("useYn")}
                        >
                            <option value="Y">사용</option>
                            <option value="N">미사용</option>
                        </select>
                    </div>
                </div>

                <div className='input_group'>
                    <label>SMS 연동</label>
                    <div className='input'>
                        <div className={UserStyle.openSmsModal} onClick={openSmsModal}>
                            SMS 연동 상세조회
                        </div>
                    </div>
                </div>

            </form>

            <footer>
                <Button
                    label="취소"
                    severity="secondary"
                    raised
                    icon="pi pi-times"
                    size="sm"
                    onClick={rendering}
                />
                <Button
                    label="수정"
                    severity="success"
                    raised
                    icon="pi pi-check"
                    size="sm"
                    onClick={handleSubmit(onUpdateHandler)}
                />
                <Button
                    label="삭제"
                    severity="danger"
                    raised
                    icon="pi pi-trash"
                    size="sm"
                    onClick={onDeleteHandler}
                />
            </footer>

            <Modal
                
                headerText="SMS 연동"
                size="modal-sm"
                modal={smsModal}
            >
            </Modal>
        </div>
    );
}

export default UserEditModal;