import React, { useEffect, useState, useContext } from "react";
import {Routes, Route, Navigate, useNavigate } from "react-router-dom";

function Signup(){

    return (
        <div>
            Signup
        </div>
    );
}

export default Signup;