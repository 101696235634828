import React, { useEffect, useState, useContext } from "react";
import {Routes, Route, Navigate, useNavigate } from "react-router-dom";
import {responseMsg} from "../../common/ResponseMsg";
import {useForm} from "react-hook-form";
import {Button} from "primereact/button";
import Swal from "sweetalert2";

function AllianceAddModal({rendering}) {
    const { register, handleSubmit, formState: { isSubmitting, isDirty, errors }} = useForm();

    const onSubmitHandler = (formData) => {

        fetch("/api/system/alliances",{
            method:"POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    Swal.fire("등록완료");
                    rendering();

                }  else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }

    return (
        <div>
            <form className="input_wrap">
                <div className="input_group">
                    <label>소속코드</label>
                    <div className='input'>
                        <input
                            className="text_input"
                            type="text"
                            name="allianceCode"
                            {...register("allianceCode",{
                                required: "소속코드를 입력해주세요.",
                            })}
                        />
                        {errors.allianceCode?.type == 'required' && 
                            <div className="error_message">
                                <p>{errors.allianceCode?.message}</p>
                            </div>
                        } 
                    </div>
                </div>

                <div className="input_group">
                    <label>소속명</label>
                    <div className='input'>
                        <input
                            className="text_input"
                            type="text"
                            name="allianceName"
                            {...register("allianceName",{
                                required: "소속명을 입력해주세요.",
                            })}
                        />
                        {errors.allianceName?.type == 'required' && 
                            <div className="error_message">
                                <p>{errors.allianceName?.message}</p>
                            </div>
                        } 
                    </div>
                </div>

            </form>

            <footer>
                <Button
                    label="취소"
                    severity="secondary"
                    raised
                    icon="pi pi-times"
                    size="sm"
                    onClick={rendering}
                />
                <Button
                    label="추가"
                    severity="success"
                    raised
                    icon="pi pi-check"
                    size="sm"
                    onClick={handleSubmit(onSubmitHandler)}
                />
            </footer>

        </div>
    );
}

export default AllianceAddModal;