import React, { useEffect, useState } from "react";
import SensorStyle from "./SensorStyle.module.css";
import { responseMsg } from "../common/ResponseMsg";
import GridTable from "../common/GridTable";
import DataChart from "./DataChart";
import moment from 'moment';
import { gridBadgeRender, getStatusGroupColor } from "../common/Common";

function DetailComponent(props) {
    const [ selectedSensor, setSelectedSensor] = useState(props.data);
    const [ measurement, setMeasurement ] = useState();
    const [ pagination, setPagination ] = useState();

    const apiGetSensorsLog = (page) => {
        const pagingSize = 20;

        if (props.searchProps.interval) {
            const tmp = {...props.searchProps, startDate:moment(props.searchProps.startDate).format("YYYY.MM.DD HH:mm:ss")}

            fetch(`/api/system/sensors/${selectedSensor.imei}/${tmp.startDate}/${tmp.endDate}?page=${page-1}&size=${pagingSize}`, {
                method: "GET"
            })
            .then((res) => res.json())
            .then((json) => {
                if(json.code === responseMsg.SUCCESS.code) {
                    setMeasurement(json.data.map(ele => {
                        return {
                            ...ele,
                            create_dtm_day:moment(ele.create_dtm).format("YYYY-MM-DD"),
                            create_dtm_time:moment(ele.create_dtm).format("HH:mm"),
                        };
                    }));

                    setPagination(json.pagination);
                }
            })
        }
    }

    useEffect(() => {
        apiGetSensorsLog(1);
        props.setDetailGrid(gridConfig.columnDefs);

    }, [selectedSensor, props.searchProps,])

    // gridTable config
    const gridConfig = {
        columnDefs: [
            { headerName: '번호', flex:0.1, minWidth:80, valueGetter: `node.rowIndex + ${pagination && (pagination.currentPageNo - 1) * pagination.recordsPerPage + 1}` },
            { headerName: '생성일', flex:0.1,minWidth:150, field: 'create_dtm', hide:true },
            { headerName: '날짜', flex:0.1,minWidth:150, field: 'create_dtm_day',notExcel:true,},
            { headerName: '시간', flex:0.1,minWidth:100, field: 'create_dtm_time',notExcel:true},
            { headerName: '온도(°C)', flex:0.1,minWidth:100, field: 'temp', cellRenderer: (props) => gridBadgeRender(props.value,"white","red")},
            { headerName: '습도(%)', flex:0.1,minWidth:100, field: 'humidity', cellRenderer: (props) => gridBadgeRender(props.value,"white","blue")},
            { headerName: '풍속(m/s)', flex:0.1,minWidth:100, field: 'windSpeed', cellRenderer: (props) => gridBadgeRender(props.value,"white","green")},
            { headerName: '기상청 온도(°C)', flex:0.1,minWidth:100, field: 'kmaTemp', cellRenderer: (props) => props.value ? gridBadgeRender(props.value,"white","red") : gridBadgeRender("수집중...","white","#a1a1a7e6")},
            { headerName: '기상청 습도(%)', flex:0.1,minWidth:100, field: 'kmaHumidity', cellRenderer: (props) =>props.value ? gridBadgeRender(props.value,"white","blue") : gridBadgeRender("수집중...","white","#a1a1a7e6")},
            { headerName: '기상청 풍속(m/s)', flex:0.1,minWidth:100, field: 'kmaWindSpeed', cellRenderer: (props) =>props.value ? gridBadgeRender(props.value,"white","green") : gridBadgeRender("수집중...","white","#a1a1a7e6")},
            { headerName: '평균속도', flex:0.1,minWidth:100, field: 'avgSpeed',},
            { headerName: '최고속도', flex:0.1,minWidth:100, field: 'speed',},
            { headerName: '표출문구', flex:0.1,minWidth:100, field: 'status', cellRenderer: (props) => gridBadgeRender(props.value, getStatusGroupColor(props.data.statusGroup),"white")}
        ],
        pagination: true,
        suppressRowHoverHighlight: true,
        rowData: measurement,
    }


    return (
        <>
            { props.customBtnWrap && ( props.customBtnWrap ) }                   
            {
                props.showChart && <DataChart 
                    block={true}
                    searchProps={props.searchProps}
                    selectedSensor={selectedSensor}
                />
            }
            {
                !props.showChart && 
                <div style={{flex:1,minWidth:"1200px"}}>
                    <GridTable
                        menu="SENSOR"
                        url="/api/system/sensor"
                        config={gridConfig}
                        pagination={pagination}
                        pageClick={apiGetSensorsLog}
                        block={true}
                    />     
                </div>
            }
        </>
    )
}

export default DetailComponent;