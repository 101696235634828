import SensorStyle from "../SensorStyle.module.css"
import "ol/ol.css";
import { responseMsg } from "../../common/ResponseMsg";
import React, { useEffect, useState, useContext, useRef } from "react";
import { Map as OlMap, View, Feature } from "ol";
import { OSM } from "ol/source";
import { fromLonLat, transform, get as getProj } from 'ol/proj';
import { Tile as TileLayer, Vector as  VectorLayer } from 'ol/layer'; // 지도타일

import Overlay from 'ol/Overlay';
import { Control, defaults as defaultControls, ZoomSlider, ZoomToExtent, FullScreen } from 'ol/control';

// 마커
import { Point } from "ol/geom";
import { Style, Icon, Fill ,Stroke } from "ol/style";
import ico_point from "../../../assets/img/map/point.svg";
import { Vector as VectorSource } from "ol/source";
import { set } from "ol/transform";

// 검색 유틸 컨트롤러
class SearchControl extends Control {
    constructor(opiton) {

        super({
            element: opiton.ref.current,
        });
    }
}

function Map(props) {
    const [ mapObject, setMapObject ] = useState({}); // 맵 객체
    const { viewData, clickEvent } = props;
    const [markerData, setMarkerData] = useState();
    const [markerLayer, setMarkerLayer] = useState({});

    const [searchText, setSearchText] = useState('');
    const [searchAddressType,setSearchAddressType] = useState('road');
    const searchRef = useRef(); 

    const getXY = () => {

        fetch('/api/system/sensors/geo-search',{
            method:"POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({address:searchText,addressType:searchAddressType}),
        })
        .then((res) => res.json())
        .then((json) => {
            if (json.code === responseMsg.SUCCESS.code) {
                const x = json.data.lon
                const y = json.data.lat
                clickEvent([x,y]);
                setMarkerData({x: x, y: y})

                mapObject.map.setView(new View({
                    projection: getProj('EPSG:3857'),
                    center: fromLonLat([x, y], getProj('EPSG:3857')),
                    zoom: mapObject.map.getView().getZoom(),
                }))
            } else if (json.code === responseMsg.FAIL.code) {
                alert("통신이 지연되고 있습니다..");
            }
        })
        .catch(error => {
            console.log(error)
        });
    }

    useEffect(() => {
        const map = new OlMap({
            controls: defaultControls().extend([
                new SearchControl({ref:searchRef}),
            ]),
            layers: [
                new TileLayer({
                    source: new OSM(),
                    name: "OSM",
                })
            ],
            target: "map",
            view: new View({
                projection: getProj('EPSG:3857'),
                center: fromLonLat(viewData === null ? [126.75, 37.47] : [viewData.x, viewData.y], getProj('EPSG:3857')),
                zoom: 15
            })
        });

        map.on('click',  handleMapClick);
        setMarkerData(viewData);
        setMapObject({ map });
    }, []);

    const handleMapClick = (e) => {
        const clickedCoordinate = e.coordinate;
        const transformCoordinate = transform(clickedCoordinate, 'EPSG:3857', 'EPSG:4326');

        if(clickEvent) {
            clickEvent(transformCoordinate);
            setMarkerData({x: transformCoordinate[0], y: transformCoordinate[1]})
        }
    }

    useEffect(() => {
        if(mapObject.map) {
            if(markerData) {
                mapObject.map.removeLayer(markerLayer);

                const iconFeature = new Feature({
                    geometry: new Point(fromLonLat([markerData.x, markerData.y], getProj("EPSG:3857"))),
                    name: null,
                    population: 4000,
                    rainfall: 500,
                });

                const iconStyle = new Style({
                    image: new Icon({
                        anchor: [0.5, 36],
                        anchorXUnits: "fraction",
                        anchorYUnits: "pixels",
                        src: ico_point,
                    }),
                });

                iconFeature.setStyle(iconStyle);

                const marker = new VectorLayer({
                    source: new VectorSource({
                        features: [iconFeature],
                    }),
                });

                mapObject.map.addLayer(marker);
                setMarkerLayer(marker);
            }
        }
    }, [viewData, markerData])

    return (
        <>
            <div id="map" value={mapObject} style={{height: '100%', width: '100%', position: ''}}></div>      
            <div ref={searchRef} className={SensorStyle.mapSearch}>
                <div className={SensorStyle.mapSearchInput}>
                    <input
                        type="text"
                        value={searchText}
                        onChange={({target}) => {
                            setSearchText(target.value);
                        }}
                    />
                    <button onClick={getXY}>검색</button>
                </div>
                <ul 
                    className={SensorStyle.mapSearchType}
                >
                    <li data-type={'road'} className={searchAddressType === 'road' ? SensorStyle.addressSelected : ''}>
                        <label htmlFor="road">도로명</label>
                        <input id="road" type="radio" name="type" value={"road"} defaultChecked={true} onClick={({target}) => setSearchAddressType(target.value)}/>
                    </li>
                    <li data-type={'parcel'} className={searchAddressType === 'parcel' ? SensorStyle.addressSelected : ''}>
                        <label htmlFor="parcel">지번</label>
                        <input id="parcel" type="radio" name="type" value={"parcel"} onClick={({target}) => setSearchAddressType(target.value)}/>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default Map;