import React from "react";

function Modal({ headerText, size, modal}) {

    return (
        <div className={modal.open ? "openModal modal" : "modal"}>
            {modal.open ? (
                <section className={size}>

                    <header>
                        {modal.headerText ? modal.headerText : headerText}
                        <button className="close" onClick={modal.close}>
                            &times;
                        </button>
                    </header>

                    <main>
                        {modal.main}
                    </main>
                </section>
            ) : null}
        </div>
    );
}

export default Modal;
