import AllianceStyle from './AllianceStyle.module.css';
import React, { useEffect, useState, useContext } from "react";
import {useForm, Controller} from "react-hook-form";
import {Routes, Route, Navigate, useNavigate } from "react-router-dom";
import {responseMsg} from "../../common/ResponseMsg";
import {Checkbox} from 'primereact/checkbox';
import {Button} from "primereact/button";
import Swal from "sweetalert2";

function AllianceEditModal({alliance, rendering}) {
    const { register, handleSubmit, formState: { isSubmitting, isDirty, errors }} = useForm({
        defaultValues : alliance
    });
    const [regions, setRegions] = useState([]);
    const [selectedRegion1, setSelectedRegion1] = useState({});
    const [checkBox, setCheckBox] = useState(alliance.regions.map(ele => ele.id));

    const onUpdateHandler = (formData) => {

        fetch("/api/system/alliances",{
            method:"PATCH",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({...formData, regions: checkBox.join('/')}),
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    Swal.fire("수정완료");
                    rendering();

                }  else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            }).catch((error) => {
                console.log(error)
            });
    }

    const checkBoxChangeHandler = (event) => {
        const isChecked = event.target.checked ? true : false;
        let tmpMap = [];

        if (isChecked) {
            tmpMap = [...checkBox, event.target.name];
        } else {
            tmpMap = [...checkBox].filter(ele => ele !== event.target.name);
        }

        if (event.target.name === selectedRegion1.id) {
            const allRegions = regions.filter(ele => ele.parent === event.target.name).map(ele => ele.id);

            if (isChecked) {
                const set = new Set([...tmpMap, ...allRegions])
                tmpMap = [...set];
            } else {
                const deleted = tmpMap.filter(ele => {
                    for(let ele2 of [...allRegions]) {
                        if (ele == ele2) {

                            return false;
                        }
                    }
                    return true;
                })

                tmpMap = deleted;
            }
        }

        setCheckBox(tmpMap);
    }

    const onDeleteHandler = () => {
        fetch(`/api/system/alliances/${alliance.id}`, {
            method: "DELETE",
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    Swal.fire("삭제완료");
                    rendering();

                } else if (json.code === responseMsg.ALLIANCE_FOUND_USER.code) {
                    Swal.fire(
                        '해당 소속을 가지는 유저가 있습니다.',
                        `아이디-이름 [${json.data.map(ele => `${ele.userId}-${ele.userNm}`).join('')}]`,
                        'error'
                      )
                    rendering();

                } else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }

    useEffect(() => {
        fetch("/api/system/codes/REGION",{
            method:"GET"
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    setRegions(json.data)
                    setSelectedRegion1(json.data.find((ele) => ele.depth === 2));

                } else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch(error => {
                console.log(error)
            });
    },[])

    return (
        <div>
            <form className="input_wrap">

                <div className="input_group">
                    <label>소속코드</label>
                    <div className='input'>
                        <input
                            className="text_input"
                            type="text"
                            name="allianceCode"
                            {...register("allianceCode",{
                                required: "소속코드를 입력해주세요.",
                            })}
                        />
                        {errors.allianceCode?.type == 'required' && 
                            <div className="error_message">
                                <p>{errors.allianceCode?.message}</p>
                            </div>
                        } 
                    </div>
                </div>

                <div className="input_group">
                    <label>소속명</label>
                    <div className='input'>
                        <input
                            className="text_input"
                            type="text"
                            name="allianceName"
                            {...register("allianceName",{
                                required: "소속명을 입력해주세요.",
                            })}
                        />
                        {errors.allianceName?.type == 'required' && 
                            <div className="error_message">
                                <p>{errors.allianceName?.message}</p>
                            </div>
                        } 
                    </div>
                </div>

                <div className="input_group">
                    <label>시/도 권한</label>
                    <div className="check_input">
                        {regions?.filter(ele => ele.depth === 2)?.map(ele => {

                            const children =  alliance?.regions.filter(ele2 => ele2.parent == ele.id);

                            return (
                                <div 
                                    key={ele.id} 
                                    className={`${AllianceStyle.checkBoxWrap} ${selectedRegion1.id === ele.id && AllianceStyle.selected}`}
                                    onClick={() => setSelectedRegion1(ele)}
                                    style={{cursor:"pointer"}}
                                >
                                    <label htmlFor={ele.id} style={{cursor:"pointer"}}>{`${ele.name}(${children.length})`}</label>
                                </div>
                            )
                        })}
                    </div>
                </div>

                <div className='input_group'>
                    <label>군/구 권한</label>
                    <div className="check_input" style={{display:"block",minHeight:"150px"}}>
                        { selectedRegion1.id &&
                            <>
                                <div key={selectedRegion1.id} className={AllianceStyle.checkBoxWrap}>
                                    <Checkbox 
                                        name={selectedRegion1.id} 
                                        checked={checkBox.find(ele2 => ele2 === selectedRegion1.id) ? true : false} 
                                        onChange={checkBoxChangeHandler}/>

                                    <label htmlFor={selectedRegion1.id}>전체</label>
                                </div>

                                {regions?.filter(ele => ele.parent === selectedRegion1.id)?.map(ele => {

                                    return (
                                        <div key={ele.id} className={AllianceStyle.checkBoxWrap}>
                                            <Checkbox 
                                                name={ele.id} 
                                                checked={checkBox.find(ele2 => ele2 === ele.id) ? true : false} 
                                                onChange={checkBoxChangeHandler}/>

                                            <label htmlFor={ele.id}>{ele.name}</label>
                                        </div>
                                    )
                                })}
                            </>
                        }
                    </div>
                </div>

                <div className="input_group">
                    <label>사용여부</label>
                    <div className='input'>
                        <select
                            className="select_input"
                            name="useYn"
                            defaultValue="Y"
                            {...register("useYn")}
                        >
                            <option value="Y">사용</option>
                            <option value="N">미사용</option>
                        </select>
                    </div>
                </div>

            </form>

            <div className="body">

            </div>

            <footer>
                <Button
                    label="취소"
                    severity="secondary"
                    raised
                    icon="pi pi-times"
                    size="sm"
                    onClick={rendering}
                />
                <Button
                    label="수정"
                    severity="success"
                    raised
                    icon="pi pi-check"
                    size="sm"
                    onClick={handleSubmit(onUpdateHandler)}
                />
                <Button
                    label="삭제"
                    severity="danger"
                    raised
                    icon="pi pi-trash"
                    size="sm"
                    onClick={onDeleteHandler}
                />
            </footer>
        </div>
    );
}

export default AllianceEditModal;