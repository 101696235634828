import AuthStyle from './AuthStyle.module.css';
import React, { useEffect, useState, useContext } from "react";
import {Routes, Route, Navigate, useNavigate } from "react-router-dom";
import {Checkbox} from 'primereact/checkbox';
import {responseMsg} from "../../common/ResponseMsg";
import AuthEditModal from "./AuthEditModal";
import AuthAddModal from "./AuthAddModal";
import GridTable from "../../common/GridTable";
import Modal from "../../common/Modal";


function Auth({name}) {
    const [render, setRender] = useState(false);
    const [searchObj, setSearchObj] = useState();
    const [updateModal, setUpdateModal] = useState({});
    const [addModal, setAddModal] = useState({});
    const [roles, setRoles] = useState();
    const [menus, setMenus] = useState();
    const [selectedRole, setSelectedRole] = useState();

    const rendering = () => {
        closeUpdateModal();
        closeAddModal();
        setRender(!render);
    }

    // 모달 (등록)
    const openAddModal = (cell) => {

        setAddModal({
            ...addModal,
            open: true,
            close: closeAddModal,
            main: <AuthAddModal
                rendering={rendering}
            />,
        });
    };

    const closeAddModal = () => {

        setAddModal({
            ...addModal,
            open : false,
        });
    };

    // 모달 (수정)
    const openUpdateModal = (cell) => {
        const modalMenus = menus.map(ele => {
            ele['modal_select'] = ele.select;
            ele['modal_insert'] = ele.insert;
            ele['modal_update'] = ele.update;

            return ele;
        })

        setUpdateModal({
            ...updateModal,
            open: true,
            close: closeUpdateModal,
            main: <AuthEditModal
                            menus={modalMenus}
                            selectedRole={selectedRole} 
                            rendering={rendering}
                        />,
        });
    };

    const closeUpdateModal = () => {

        setUpdateModal({
            ...updateModal,
            open : false,
        });
    };

    const checkBoxRenderer = (cell) => {
        const col = cell.colDef;
        const data = cell.data;

        if (data[col.field] !== 'notPage') {

            return (
                <div className='grid_check_box'>
                    {data[col.field] !== null && <Checkbox
                        disabled
                        checked={data[col.field] ? true : false}
                    />}
                </div>
            )
        }
    }

    const leftGridConfig = {
        columnDefs: [
            { headerName: '권한ID', field: 'name', flex:1, minWidth: 150},
            { headerName: '권한명', field: 'encodedNm', flex:1, minWidth: 200},
        ],
        pagination: false,
        onCellClicked: (cell) => setSelectedRole(cell.data),
        onCellDoubleClicked: (cell) => openUpdateModal(cell),
        rowData: roles,
        rowSelection: 'single',
    };

    const rightGridConfig = {
        columnDefs: [
            { headerName: 'Level', field: 'depth', flex:1, minWidth: 100},
            { headerName: '시스템명', field: 'name', flex:1, minWidth: 150},
            { headerName: 'URL', field: 'url', flex:3, minWidth: 400},
            { headerName: '조회', field: 'select', cellRenderer: checkBoxRenderer, flex:1, minWidth: 100},
            { headerName: '등록', field: 'insert', cellRenderer: checkBoxRenderer, flex:1, minWidth: 100},
            { headerName: '수정', field: 'update', cellRenderer: checkBoxRenderer, flex:1, minWidth: 100},
        ],
        suppressRowHoverHighlight: true,
        rowData: menus,
    };

    const createMenusArr = (arr,menus) => {
        if (!menus) return;

        menus.forEach(ele => {
            arr.push(ele);
            createMenusArr(arr,ele.children);
        });
    }

    const apiGetRoleList = (page) => {
        let url = '/api/system/roles?'
        const pagingSize = 20;

        if (page) {
            url += `page=${page-1}&`
            url += `size=${pagingSize}&`
        }

        if(searchObj) {
            Object.keys(searchObj.req).forEach(ele => {
                if (searchObj.req[ele]) {
                    url += `${ele}=${searchObj.req[ele]}&`
                }
            })
            
            fetch(url,{
                method:"GET"
            })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    setSelectedRole(json.data[0]);

                    setRoles(json.data);

                } else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch((error) => {
                console.log(error)
            });
        } 
    }

    useEffect(() => {
        apiGetRoleList();
    },[render,searchObj])

    useEffect(() => {

        fetch("/api/system/menus",{
            method:"GET"
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    const menus = [];
                    createMenusArr(menus,json.data);

                    menus.forEach(ele => {
                        if (!ele.children.length) {
                            ele.select = selectedRole?.menus.find(menu => menu.id === ele.id) ? true : false;
                            ele.insert = selectedRole?.privileges.find(privilege => privilege.type === 'I' && privilege.menuId === ele.id) ? true : false;
                            ele.update = selectedRole?.privileges.find(privilege => privilege.type === 'U' && privilege.menuId === ele.id) ? true : false;
                        } else {
                            ele.select = null
                            ele.insert = null
                            ele.update = null
                        }
                    })

                    setMenus(menus);

                }  else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch((error) => {
                console.log(error)
            });
            
    },[render,roles,selectedRole])

    return (
        <div className="contents">
            <div className="page_title">{name}</div>

            <div className="page_contents">
               <div className={AuthStyle.left}>
                   <GridTable 
                        menu="AUTH"
                        selectedRow={{row:selectedRole,check:"id"}}
                        header={{add: openAddModal, search: {
                            searchSetter:setSearchObj,
                            option:[
                                {field:'encodedNm',name:'권한명',default:""},
                        ]}}}
                        config={leftGridConfig} 
                        title="" 
                        block={true}
                    />
               </div>

               <div className={AuthStyle.right}>
                   <GridTable 
                        menu="AUTH"
                        header={true}
                        config={rightGridConfig} 
                        title="" 
                        block={true}
                   />
               </div>
            </div>

            <Modal
                headerText="권한 등록"
                size="modal-md"
                modal={addModal}
            >
            </Modal>

            <Modal
                headerText="권한 수정"
                size="modal-lg"
                modal={updateModal}
            >
            </Modal>
        </div>
    );
}

export default Auth;