import React, { useEffect, useState, createContext } from "react";
import {Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";
import {responseMsg} from "./components/common/ResponseMsg";
import Login from "./components/login/Login";
import Signup from "./components/signup/Signup";
import Error404 from "./components/common/Error404";
import Layout from "./components/common/Layout";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primereact/resources/themes/mdc-light-indigo/theme.css";
import './App.css';
import './assets/css/reset.css';
import './assets/css/common.css';
import './assets/css/common_mobile.css';
import './assets/css/modal.css';

export const CommonContext = createContext();

function App() {
    const [routePages, setRoutePages] = useState();
    const [myPages, setMyPages] = useState();
    const navigate = useNavigate();
    const location = useLocation();

    function setScreenSize() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      }
      useEffect(() => {
        setScreenSize();
    });

    const createMyPagesArr = (arr,menus) => {
        if (!menus) return;

        menus.forEach(ele => {
            arr.push(ele);
            createMyPagesArr(arr,ele.children);
        });
    }

    const logout = () => {
        fetch('/api/logout')
            .then((res) => res.json())
            .then((json) => {
                if(json.code === responseMsg.SUCCESS.code){
                    localStorage.removeItem("USER");
                    window.location.reload();
                } else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    async function userRoleCheck (roleData) {
        roleData.page = roleData.page || null;
        roleData.role = roleData.role || null;

        let result;

        await fetch("/api/system/roles/check",{
            method:"POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(roleData),
        })
        .then((res) => res.json())
        .then((json) => {
            if (json.code === responseMsg.SUCCESS.code) {
                result = json.data;
            } else {
                result = false;
            }
        })

        return result;
    }

    useEffect(() => {
        if(location.pathname === '/') return;

        if(!localStorage.getItem("USER")) {
            navigate("/login")

        } else {
            fetch("/api/system/menus/my")
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    const menus = [];
                    createMyPagesArr(menus,json.data);
                    setRoutePages(menus);
                    setMyPages(json.data)
                } else if (json.code === responseMsg.UN_AUTHORIZED.code) {
                    alert("세션이 만료돼었습니다.");
                    logout();
                }
            });
        }

    },[location.pathname]);

    return (
    <div className="App">
        <CommonContext.Provider value={{myPages:myPages,logout:logout,userRoleCheck:userRoleCheck}}>
            <Routes>
                <Route path="/" element={<Navigate to={`/main`} replace />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<Signup />} />

                {routePages && routePages.map((menu) => {
                    if (menu.children.length === 0) {
                        return (
                            <Route
                                key={menu.url}
                                path={`${menu.url}`}
                                element={<Layout to={menu.url} name={menu.name} />}
                            />
                        );
                    }
                })}

                {routePages && <Route path='*' element={<Error404 />}/>}
            </Routes>
        </CommonContext.Provider>
    </div>
    );
}

export default App;
