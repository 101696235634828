
import React, { useEffect, useState, useContext } from "react";
import {Routes, Route, Navigate, useNavigate } from "react-router-dom";
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import {
    ComposedChart,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip, Legend,
    ResponsiveContainer,
    AreaChart,
    Area,
    PieChart,
    Pie,
    LineChart,
    Line,
    ReferenceLine,
    linearGradient
} from 'recharts';

const ctg = [
    {type:"line",tag:"라인"},
    {type:"bar",tag:"막대"},
    {type:"area",tag:"영역"},
]

function Chart(props) {
    const { config } = props;
    const [ colNotData, setColNotData] = useState(false);

    useEffect(() => {
        const filter = props.data.filter(ele => {
            return ele[config.lineConfig[0].dataKey] !== null;
        });

        if (filter.length) {
            setColNotData(false);
        } else {
            setColNotData(true);
        }
    },[props.data,props.chartInfo])

    const formatTimeTick = (time) => {
        return `${moment.utc(time).format("DD일")} ${moment.utc(time).format("HH:mm")}`;
    }

    return (
        <>
            <div className="chart_title">
                {props.chartInfo?
                        props.chartInfo.tab.map((ele,idx) => {
                            
                            return (
                                <p 
                                    className={idx === props.chartInfo.select ? "chart_tab select" : "chart_tab"} 
                                    onClick={() => props.setChartInfo({select:idx,tab:props.chartInfo.tab})}>
                                {ele.name}</p>
                            )
                        }) 
                    : 
                        <p>{props.title}</p>}
                <ul>
                    {ctg.map(ele => {

                        return (
                            <li 
                                key={ele.type}
                                className={config.chart === ele.type && "select"}
                                onClick={(e => {
                                    props.setChart(ele.type);
                                })}
                            >{ele.tag}
                            </li>
                        )
                    })}
                </ul>
            </div>
            <div className="chart_container">
                {props.data.length && !colNotData?
                    <ResponsiveContainer width="100%" height="100%">
                        <ComposedChart  
                            data={props.data}
                            margin={{ top: 20, right: 40, left: -10, bottom: 10 }}
                        >
                            <XAxis 
                                dataKey={config.xData} 
                                tickFormatter={formatTimeTick}
                                {...config.XAxis}
                            />
                            <YAxis {...config.YAxis}/>
                            <Tooltip labelFormatter={(value) => moment.utc(value).format("MM월DD일 HH:mm")}/>
                            <Legend/>
                            <CartesianGrid />
                            {
                                config.lineConfig.map(e => {
                                    if (e.lineType == 'line') {
                                        return (<Line dot={false} strokeWidth={2} {...e} key={e.dataKey}/>)

                                    }

                                    if (e.lineType == 'bar') {
                                        return (<Bar strokeWidth={1} {...e} key={e.dataKey}/>)
                                        
                                    }

                                    if (e.lineType == 'area') {
                                        return (<Area strokeWidth={1} {...e} key={e.dataKey}/>)
                                    }
                                })
                            }
                            {config.referenceLineY && <ReferenceLine {...config.referenceLineY}/>}
                        </ComposedChart>
                    </ResponsiveContainer>
                    : 
                    <div >데이터가 없습니다.</div>
                }
            </div>
        </>
    )
}

export default Chart;