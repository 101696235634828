import React, { useEffect, useState, useContext } from "react";
import {Routes, Route, Navigate, useNavigate } from "react-router-dom";
import {CommonContext} from "../../App";
import Header from "./Header";
import Main from "../main/Main";
import Auth from "../system/auth/Auth"
import User from "../system/user/User";
import Alliance from "../system/alliance/Alliance";
import Sensor from "../sensor/Sensor";
import Code from "../system/code/Code";
import Contact from "../contact/Contact";
import Holiday from "../holiday/Holiday";

function Layout({to,name}) {

    return (
        <>
            <div id="Layout">
                <Header />

                { to === '/main' &&  <Main name={name}/> }
                { to === '/system/auth' && <Auth name={name}/> }
                { to === '/system/user' && <User name={name}/> }
                { to === '/system/alliance' && <Alliance name={name}/> }
                { to === '/system/code' && <Code name={name}/> }
                { to === '/sensor' && <Sensor name={name}/> }
                { to === '/contact' && <Contact name={name}/> }
                { to === '/holiday' && <Holiday name={name}/> }
            </div>
        </>
    );
}

export default Layout;