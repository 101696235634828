import CodeStyle from './CodeStyle.module.css';
import React, { useEffect, useState, useContext } from "react";
import {Routes, Route, Navigate, useNavigate } from "react-router-dom";
import {responseMsg} from "../../common/ResponseMsg";
import {useForm} from "react-hook-form";
import {Button} from "primereact/button";
import Swal from "sweetalert2";

function CodeAddModal({rendering,type,selectedCode1,selectedCode2}) {
    const { register, handleSubmit, formState: { isSubmitting, isDirty, errors }} = useForm();

    const onSubmitHandler = (formData) => {
        if (type === 'Level 2') {
            formData = {
                ...formData,
                groupCode: selectedCode1.groupCode,
                parent: selectedCode1.id,
                depth: 2,
            }

        } else if (type === 'Level 3') {
            formData = {
                ...formData,
                groupCode: selectedCode1.groupCode,
                parent: selectedCode2.id,
                depth: 3,
            }
        }


        fetch("/api/system/codes",{
            method:"POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then((res) => res.json())
            .then((json) => {
                if (json.code === responseMsg.SUCCESS.code) {
                    Swal.fire("등록완료");
                    rendering();

                } else if (json.code === responseMsg.FAIL.code) {
                    alert('통신이 지연되고 있습니다..');
                }
            })
            .catch((error) => {
                console.log(error)
            });
    }

    return (
        <div>
            <form className="input_wrap">

                <div className="input_group">
                    <label>Group Code</label>
                    <div className='input'>
                        <input
                            className="text_input"
                            type="text"
                            name="groupCode"
                            disabled={true}
                            value={selectedCode1.groupCode}
                            {...register("groupCode")}
                        />
                    </div>
                </div>

                <div className="input_group">
                    <label>Parent Code</label>
                    <div className='input'>
                        <input
                            className="text_input"
                            type="text"
                            name="parent"
                            disabled={true}
                            value={type === 'Level 2' ? selectedCode1?.name : selectedCode2?.name}
                            {...register("parent")}
                        />
                    </div>
                </div>

                <div className="input_group">
                    <label>Depth</label>
                    <div className='input'>
                        <input
                            className="text_input"
                            type="text"
                            name="depth"
                            disabled={true}
                            value={type === 'Level 2' ? 2 : 3}
                            {...register("depth")}
                        />
                    </div>
                </div>

                <div className="input_group">
                    <label>코드명</label>
                    <div className='input'>
                        <input
                            className="text_input"
                            type="text"
                            name="name"
                            {...register("name",{
                                required: "코드명을 입력해주세요.",
                            })}
                        />
                        {errors.name?.type == 'required' && 
                            <div className="error_message">
                                <p>{errors.name?.message}</p>
                            </div>
                        } 
                    </div>
                </div>

                <div className="input_group">
                    <label>Comment</label>
                    <div className='input'>
                        <input
                            className="text_input"
                            type="text"
                            name="comment"
                            {...register("comment")}
                        />
                    </div>
                </div>

                <div className="input_group">
                    <label>사용여부</label>
                    <div className='input'>
                        <select
                            className="select_input"
                            name="useYn"
                            defaultValue="Y"
                            {...register("useYn")}
                        >
                            <option value="Y">사용</option>
                            <option value="N">미사용</option>
                        </select>
                    </div>
                </div>

            </form>

            <footer>
                <Button
                    label="취소"
                    severity="secondary"
                    raised
                    icon="pi pi-times"
                    size="sm"
                    onClick={rendering}
                />
                <Button
                    label="추가"
                    severity="success"
                    raised
                    icon="pi pi-check"
                    size="sm"
                    onClick={handleSubmit(onSubmitHandler)}
                />
            </footer>

        </div>
    );
}

export default CodeAddModal;